import { TimeDuration } from 'src/app/models';

export enum ApiType {
  Mutate = 'mutate',
  Query = 'query',
  MenuTallyLoading = 'menuTallyLoading',
  POSItemsManagingLoading = 'posItemsManagingLoading'
}

export class PosTimeoutConfig {
  private static readonly POS_QUERY_API_TIMEOUT_TIME = 10 * TimeDuration.Second;
  private static readonly POS_MUTATE_API_TIMEOUT_TIME =
    20 * TimeDuration.Second;

  static readonly POS_TIMEOUT_TIME_CONST: Record<ApiType, number> = {
    [ApiType.Mutate]: PosTimeoutConfig.POS_MUTATE_API_TIMEOUT_TIME,
    [ApiType.Query]: PosTimeoutConfig.POS_QUERY_API_TIMEOUT_TIME,
    [ApiType.MenuTallyLoading]: TimeDuration.Minute,
    [ApiType.POSItemsManagingLoading]: TimeDuration.Minute
  };
}

export class PosConfig {
  static readonly POS_API_TIMEOUT_TIME = (type: ApiType) =>
    PosTimeoutConfig.POS_TIMEOUT_TIME_CONST[type];

  static readonly REGISTER_DEVICE_TOKEN = 'appconfigtool:3F.42N.iJVEy9A3bP';
}
