import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'pos-app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {
  constructor() {}

  ngOnInit() {
    this.preloadComponents();
  }

  private preloadComponents() {
    const body = document.querySelector('body');
    const preloadComponents = this.createPreloadComponents();
    body.append(...preloadComponents);
    preloadComponents.forEach((component) => {
      component.remove();
    });
  }

  private createPreloadComponents(
    preloadComponentKeys = ['ion-toast', 'ion-modal']
  ) {
    return preloadComponentKeys.map((key) => document.createElement(key));
  }
}
