import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AlertButton, AlertController } from '@ionic/angular';
import {
  MonoTypeOperatorFunction,
  Subject,
  TimeoutError,
  pipe,
  retry,
  switchMap,
  throwError,
  timeout
} from 'rxjs';
import { Store } from '@ngxs/store';
//----------------------------------------------
import { CustomErrorCode, ErrorConstant } from 'src/app/constants';
import { ApiType, PosConfig } from 'src/app/pos.config';
import { PosLoadingModalService } from 'src/app/components/shared/loading-modal/service';
import { MenuConstant } from 'src/app/constants/menu.constant';
import { APP_STATE_TOKEN } from 'src/app/store/app/app.state.model';
import { ResetApplication } from 'src/app/store/app/app.action';
import { ModalService } from 'src/app/services/modal/modal.service';

type RetryPipe<T = any> = MonoTypeOperatorFunction<T>;

@Injectable({ providedIn: 'root' })
export class ErrorService {
  private retryAlertNotifiers: Subject<string>[] = [];
  private isRetryAlertPresent = false;
  private isAlertErrorPresent = false;

  constructor(
    private readonly alertCtrl: AlertController,
    private readonly modalService: ModalService,
    private readonly posLoadingModal: PosLoadingModalService,
    private readonly store: Store
  ) {}

  alertError(message: string) {
    this.alertCtrl
      .create({
        header: 'Error',
        message,
        backdropDismiss: false,
        cssClass: 'alert-message',
        buttons: [
          {
            text: 'OK',
            role: 'ok'
          }
        ]
      })
      .then((alert) => {
        alert.present();
        alert.onWillDismiss().then(() => {
          this.isAlertErrorPresent = false;
        });
      });
  }

  retryPipe = <T>(type?: ApiType): RetryPipe<T> => {
    const retryFunc = retry({ delay: (error) => this.retry(error) });
    const timeoutFunc = () => timeout(PosConfig.POS_API_TIMEOUT_TIME(type));

    switch (type) {
      case ApiType.MenuTallyLoading:
        const menuTallyLoadingRetryFunc = retry({
          delay: (error) => {
            this.posLoadingModal.dismiss();
            return this.retry(error).pipe(
              switchMap(() =>
                this.posLoadingModal.presentLoadingModal(
                  MenuConstant.LOADING_MODAL_MSG.MENU_TALLY_LOADING,
                  MenuConstant.LOADING_MODAL_MSG.MENU_TALLY_LOADING_LONGER
                )
              )
            );
          }
        });

        return pipe(timeoutFunc(), menuTallyLoadingRetryFunc) as RetryPipe;
      case ApiType.POSItemsManagingLoading:
        const posItemsLoadingRetryFunc = retry({
          delay: (error) => {
            this.posLoadingModal.dismiss();
            return this.retry(error).pipe(
              switchMap(() =>
                this.posLoadingModal.presentLoadingModal(
                  MenuConstant.LOADING_MODAL_MSG.LOADING_ITEMS,
                  MenuConstant.LOADING_MODAL_MSG.MENU_TALLY_LOADING_LONGER
                )
              )
            );
          }
        });

        return pipe(timeoutFunc(), posItemsLoadingRetryFunc) as RetryPipe;
      case ApiType.Mutate:
      case ApiType.Query:
        return pipe(timeoutFunc(), retryFunc) as RetryPipe;
      default:
        return pipe(retryFunc) as RetryPipe;
    }
  };

  private readonly retry = (
    error: HttpErrorResponse | TimeoutError | Error,
    retryMessage?: string
  ) => {
    const isTimeoutError = error instanceof TimeoutError;
    const httpStatus = (error as HttpErrorResponse).status;
    const isTimeoutOrServerError =
      isTimeoutError ||
      httpStatus === 0 ||
      httpStatus === HttpStatusCode.InternalServerError;
    const isNoNetwork =
      !this.store.selectSnapshot(APP_STATE_TOKEN).networkConnected;
    const isUnauthorized = httpStatus === HttpStatusCode.Unauthorized;

    const isError =
      isNoNetwork ||
      isUnauthorized ||
      (!isTimeoutOrServerError && !retryMessage);

    this.modalService.closeLoadingModal();

    if (isError) {
      let alertMessage = null;

      if (isUnauthorized) {
        alertMessage =
          'The session has expired or the device was unregistered.';
        this.store.dispatch(new ResetApplication());
      } else if (isNoNetwork) {
        alertMessage = this.getErrorMessageByCode(
          CustomErrorCode.DeviceOffline
        );
      }

      if (alertMessage && !this.isAlertErrorPresent) {
        this.isAlertErrorPresent = true;
        this.alertError(alertMessage);
      }

      return throwError(() => error);
    }

    const retryNotifier = new Subject<string>();

    this.retryAlertNotifiers.push(retryNotifier);

    this.alertCtrl.getTop().then(() => {
      if (!this.isRetryAlertPresent) {
        this.isRetryAlertPresent = true;
        this.retryAlert(
          this.getErrorMessageByParam(retryMessage, isTimeoutError, httpStatus)
        ).then((isRetry) => {
          if (isRetry) {
            this.retryAlertNotifiers.forEach((notifier) => notifier.next(''));
          } else {
            this.retryAlertNotifiers.forEach((notifier) =>
              notifier.error(error)
            );
          }

          this.retryAlertNotifiers = [];
          this.isRetryAlertPresent = false;
        });
      }
    });

    return retryNotifier.asObservable();
  };

  private getErrorMessageByCode(code: string | number): string {
    return ErrorConstant.ErrorMessageByCode[code] || 'N/A Error';
  }

  private getErrorMessageByParam(
    retryMessage: string,
    isTimeoutError: boolean,
    httpStatus: number
  ): string {
    return retryMessage
      ? retryMessage
      : isTimeoutError
      ? this.getErrorMessageByCode(CustomErrorCode.RequestTimeout)
      : httpStatus === 0
      ? this.getErrorMessageByCode(CustomErrorCode.HttpNoResponseError)
      : this.getErrorMessageByCode(HttpStatusCode.InternalServerError);
  }

  private async retryAlert(message: string): Promise<boolean> {
    const buttons: (string | AlertButton)[] = [
      { text: 'CANCEL', cssClass: 'alert-button-cancel' },
      { text: 'RETRY', role: 'retry', cssClass: 'alert-button-retry' }
    ];
    const alert = await this.alertCtrl.create({
      cssClass: 'custom-alert-error-1',
      header: 'ERROR',
      mode: 'md',
      message,
      buttons
    });
    await alert.present();
    const result = await alert.onDidDismiss();
    return result.role === 'retry';
  }
}
