import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { append, patch } from '@ngxs/store/operators';
import { forkJoin, tap } from 'rxjs';
import { produce } from 'immer';
import _ from 'lodash';
//=========================================================
import {
  DateHelper,
  GeneralHelper,
  MacroHelper,
  MenuHelper
} from 'src/app/helpers';
import {
  LocationType,
  MacroGrid,
  MacroGridItem,
  MacroGridType,
  MacroGridView,
  Menu,
  MenuItem,
  MenuItemType,
  MenuTallyFoodDetail,
  PriceType,
  ProductType,
  RequestMealId
} from 'src/app/models';
import { DinerService, MenuService, ModalService } from 'src/app/services';

import {
  BackToSelectedMacroGrid,
  DeleteQuantityRestriction,
  GetMacroGridDetail,
  GetMacroGridDetailSuccess,
  GetMacroGrids,
  GetMacroGridsSuccess,
  GetMenu,
  GetMenuData,
  GetMenuSuccess,
  GetMenuTally,
  GetMenuTallySuccess,
  ResetGridView,
  ResetMacroGrid,
  SetSelectedMealId,
  ShowModifierGrid,
  UpdateMacroGridView,
  UpdateQuantityRestriction,
  UpdateSelectedMacroGrid,
  UpdateSelectedModifierMacroGrid
} from './menu.action';
import { MenuStateHelper } from './menu.state.helper';
import {
  CreateTicketSuccess,
  RemoveTicketSuccess
} from 'src/app/store/tickets/tickets.action';
import { UpdateSelectedMealId } from 'src/app/store/location/location.action';
import { TICKETS_STATE_TOKEN } from 'src/app/store/tickets/tickets.state';
import { MenuStateModel, MENU_STATE_TOKEN } from './menu.state.model';
import { SelectNewSeat } from 'src/app/store/seats/seats.action';
import { ErrorConstant } from 'src/app/constants';
import { PosQuantityRestriction } from 'src/app/models/pos-manager.model';
import { LOCATION_STATE_TOKEN } from 'src/app/store/location/location.state';
import { LocationStateHelper } from 'src/app/store/location/location.state.helper';
import { PosLoadingModalService } from 'src/app/components/shared/loading-modal/service';

@State<MenuStateModel>({
  name: MENU_STATE_TOKEN,
  defaults: {
    menu: null,
    menu_by_meal: {},
    menuItemsWithModifierPrompts: [],
    menuItemsWithModifierPromptsByMeal: {},
    barcodeProducts: null,
    barcodeProductsByMeal: {},
    macro_grid_view: MacroGridView.MAIN,
    macroGrids: {
      isLoaded: false,
      isProcessing: false,
      data: []
    },
    // macro_grids_list: [],
    default_macro_grid: null,
    default_macro_grid_by_meal: {},
    default_modifier_macro_grid: null,
    default_modifier_macro_grid_by_meal: {},
    selected_macro_grid: null,
    selected_modifier_macro_grid: null,
    macro_stack: [],
    modifier_macro_stack: [],
    isModifierGridVisible: false,
    menu_tally: {},
    menu_selected_date_meal_id: null,
    menu_date: null,
    selected_meal_id: null,
    quantity_restrictions: {}
  }
})
@Injectable()
export class MenuState {
  constructor(
    private readonly store: Store,
    private readonly menuService: MenuService,
    private readonly dinerService: DinerService,
    private readonly modalService: ModalService,
    private readonly posLoadingModal: PosLoadingModalService
  ) {}

  //Return the Selected Macro Grid
  @Selector()
  static getSelectedMacroGrid({
    isModifierGridVisible,
    selected_modifier_macro_grid,
    selected_macro_grid
  }: MenuStateModel) {
    return isModifierGridVisible
      ? selected_modifier_macro_grid
      : selected_macro_grid;
  }

  //Return the Macro Grid Items List
  @Selector([MenuState.getSelectedMacroGrid])
  static getMacroGridItems(
    state: MenuStateModel,
    selectedMacroGrid: MacroGrid | MacroGridItem
  ): MacroGridItem[] {
    const selectedMenuDate = MenuHelper.getSelectedMenuDate(
      state.menu_selected_date_meal_id
    );
    return 'macro_grid_items' in selectedMacroGrid
      ? MenuHelper.applyRestrictionForMacroGridItem(
          selectedMacroGrid.macro_grid_items,
          state.quantity_restrictions[selectedMenuDate]
        )
      : [];
  }

  @Selector([MenuState.getSelectedMacroGrid])
  static getMacroGrid(
    state: MenuStateModel,
    selectedMacroGrid: MacroGrid | MacroGridItem
  ): MacroGrid {
    return selectedMacroGrid && 'macro_grid_items' in selectedMacroGrid
      ? selectedMacroGrid
      : null;
  }

  @Selector([MenuState.getSelectedMacroGrid])
  static getKioskMacroGridItems(
    state: MenuStateModel,
    selectedMacroGrid: MacroGrid | MacroGridItem
  ): MacroGridItem[] {
    let items: MacroGridItem[] = [];

    if ('macro_grid_items' in selectedMacroGrid) {
      items = this.kioskMacroGridFilter(selectedMacroGrid);
    }

    return items;
  }

  //Return the default Macro Grid
  @Selector()
  static getDefaultMacroGrid(state: MenuStateModel) {
    return state.isModifierGridVisible
      ? state.default_modifier_macro_grid
      : state.default_macro_grid;
  }

  @Selector()
  static getProducts(state: MenuStateModel) {
    return state.menu && _.cloneDeep(state.menu.products);
  }

  @Selector()
  static getKioskSelectedMarcoGrid({ selected_macro_grid }: MenuStateModel) {
    const macroGrid = _.cloneDeep(selected_macro_grid);
    if ('macro_grid_items' in macroGrid) {
      // POSV3-969 filter out the macro grid with is a product with
      macroGrid.macro_grid_items = this.kioskMacroGridFilter(macroGrid);
    }

    return macroGrid;
  }

  //#region Actions

  //#region Init Data

  //Dispatch the actions to get Macro Grid and Menu Data
  @Action(GetMenuData)
  getMenuData(
    { dispatch }: StateContext<MenuStateModel>,
    { isLoadSpecificMenu }: GetMenuData
  ) {
    const locationType =
      this.store.selectSnapshot(LOCATION_STATE_TOKEN).location.type;
    const isDiningRoom: boolean = locationType === LocationType.DiningRoom;

    // incase dining room and switch location, it should reload menu
    if (isDiningRoom || !isLoadSpecificMenu) {
      dispatch(GetMenu);
    }
  }

  //Call the request to get the Macro Grid Data
  @Action(GetMacroGrids)
  getMacroGrids({ dispatch, patchState }: StateContext<MenuStateModel>) {
    patchState({
      macroGrids: { isLoaded: false, isProcessing: true, data: [] }
    });
    return this.menuService
      .getMacroGrids()
      .pipe(
        tap((macro_grids_list) =>
          dispatch(new GetMacroGridsSuccess(macro_grids_list))
        )
      );
  }

  @Action(GetMacroGridsSuccess)
  getMacroGridSuccess(
    { patchState }: StateContext<MenuStateModel>,
    { macro_grids_list }: GetMacroGridsSuccess
  ) {
    if (macro_grids_list.length === 0) {
      this.modalService.alert({
        header: 'Error',
        message: ErrorConstant.EmptyMacroGridsErrorMessage,
        buttons: ['OK']
      });
    }
    patchState({
      macroGrids: {
        isLoaded: true,
        data: macro_grids_list,
        isProcessing: false
      }
    });
  }

  //Call the request to get the Macro Grid Detail data and dispatch action to config the default Macro Grid and Macro Grid Modifier
  @Action(GetMacroGridDetail)
  getMacroGridDetail(
    ctx: StateContext<MenuStateModel>,
    { meal_id, runSecondTime }: GetMacroGridDetail
  ) {
    const state = ctx.getState();
    const {
      macroGrids: macroGirdsState,
      default_macro_grid_by_meal,
      default_modifier_macro_grid_by_meal
    } = state;

    const isLoadedMacroGrid = _.has(default_macro_grid_by_meal, meal_id);
    const isLoadedModifierGrid = _.has(
      default_modifier_macro_grid_by_meal,
      meal_id
    );
    const macroGridList = macroGirdsState.data;

    if (!macroGirdsState.isLoaded && !macroGirdsState.isProcessing) {
      if (runSecondTime || (isLoadedMacroGrid && isLoadedModifierGrid)) {
        // fix infinity loop, just allow it call 2 times maximum, this case will happened incase these are no macro gird config for the list.
        return;
      }

      return ctx
        .dispatch(new GetMacroGrids())
        .pipe(tap(() => ctx.dispatch(new GetMacroGridDetail(meal_id, true))));
    }

    const cacheMacroGrid = isLoadedMacroGrid
      ? default_macro_grid_by_meal[meal_id]
      : null;
    const cacheModifierGrid = isLoadedModifierGrid
      ? default_modifier_macro_grid_by_meal[meal_id]
      : null;

    const macroGridId = cacheMacroGrid
      ? null
      : _.find(
          macroGridList,
          (macro) =>
            macro.type === MacroGridType.FOOD ||
            macro.type === MacroGridType.PRODUCTS
        )?.id;
    const modifierMacroGridId = cacheModifierGrid
      ? null
      : _.find(macroGridList, (macro) => macro.type === MacroGridType.MODIFIER)
          ?.id;

    ctx.patchState({
      selected_meal_id: meal_id
    });

    if (isLoadedMacroGrid) {
      ctx.patchState({
        default_macro_grid: cacheMacroGrid,
        selected_macro_grid: cacheMacroGrid
      });
    }

    if (isLoadedModifierGrid) {
      ctx.patchState({
        default_modifier_macro_grid: cacheModifierGrid,
        selected_modifier_macro_grid: cacheModifierGrid
      });
    }

    if (isLoadedMacroGrid && isLoadedModifierGrid) {
      return;
    }

    if (!_.isEmpty(_.compact([macroGridId, modifierMacroGridId]))) {
      ctx.setState(
        produce((draft) => {
          draft.default_macro_grid_by_meal = _.merge(
            draft.default_macro_grid_by_meal,
            { [meal_id]: null }
          );
          draft.default_modifier_macro_grid_by_meal = _.merge(
            draft.default_modifier_macro_grid_by_meal,
            { [meal_id]: null }
          );
        })
      );
    }

    return forkJoin(
      _.compact([macroGridId, modifierMacroGridId]).map((id) =>
        this.menuService.getMacroGridDetail(meal_id, id)
      )
    ).pipe(
      tap((macroGrids) => {
        const default_macro_grid = this.formatMacroGrids(
          macroGrids.find((i) => i.id === macroGridId)
        );
        const default_modifier_macro_grid =
          modifierMacroGridId &&
          this.formatMacroGrids(
            macroGrids.find((i) => i.id === modifierMacroGridId)
          );

        ctx.dispatch(
          new GetMacroGridDetailSuccess(
            meal_id,
            default_macro_grid,
            default_modifier_macro_grid
          )
        );
      })
    );
  }

  @Action(GetMacroGridDetailSuccess)
  getMacroGridDetailSuccess(
    ctx: StateContext<MenuStateModel>,
    {
      mealId: meal_id,
      macroGrid: default_macro_grid,
      modifierMacroGrid: default_modifier_macro_grid
    }: GetMacroGridDetailSuccess
  ) {
    ctx.setState(
      produce((draft) => {
        draft.default_macro_grid = default_macro_grid;
        draft.selected_macro_grid = default_macro_grid;
        draft.default_macro_grid_by_meal = _.merge(
          draft.default_macro_grid_by_meal,
          { [meal_id]: default_macro_grid }
        );

        draft.default_modifier_macro_grid = default_modifier_macro_grid;
        draft.selected_modifier_macro_grid = default_modifier_macro_grid;
        draft.default_modifier_macro_grid_by_meal = _.merge(
          draft.default_modifier_macro_grid_by_meal,
          { [meal_id]: default_modifier_macro_grid }
        );
      })
    );
  }

  //Call the request to get the Menu Data
  @Action(GetMenu)
  getMenu(ctx: StateContext<MenuStateModel>, { mealId, date }: GetMenu) {
    const ticketDate = date || DateHelper.format(new Date());
    const requestMealId = this.getRequestMealId(ticketDate, mealId);
    const state = ctx.getState();
    const menu_by_meal = state.menu_by_meal;
    const menuItemsWithModifierPromptsByMeal =
      state.menuItemsWithModifierPromptsByMeal;
    const barcodeProductsByMeal = state.barcodeProductsByMeal;
    const locationType =
      this.store.selectSnapshot(LOCATION_STATE_TOKEN).location.type;
    const isServiceAppointment: boolean =
      locationType === LocationType.Services;

    if (requestMealId || isServiceAppointment) {
      // the check meal id in barcodeProductsByMeal is not required due to products may not mandatory for some facilities
      if (
        _.has(menu_by_meal, requestMealId.dateMealString) &&
        !_.isEmpty(menu_by_meal[requestMealId.dateMealString]) &&
        _.has(
          menuItemsWithModifierPromptsByMeal,
          requestMealId.dateMealString
        ) &&
        !_.isEmpty(
          menuItemsWithModifierPromptsByMeal[requestMealId.dateMealString]
        )
      ) {
        ctx.patchState({
          menu_date: requestMealId.date,
          menu: menu_by_meal[requestMealId.dateMealString],
          menu_selected_date_meal_id: requestMealId.dateMealString,
          menuItemsWithModifierPrompts:
            menuItemsWithModifierPromptsByMeal[requestMealId.dateMealString],
          barcodeProducts: _.has(
            barcodeProductsByMeal,
            requestMealId.dateMealString
          )
            ? barcodeProductsByMeal[requestMealId.dateMealString]
            : {}
        });
        return;
      }

      ctx.setState(
        produce((draft) => {
          /**
           * POSV3-1661: I have implemented a temporary method to cache the menu based on meal and date before making the API call.
           * Refactoring the conflicting action would be too complex at the moment.
           */
          draft.menu_by_meal = {
            ...draft.menu_by_meal,
            [requestMealId.dateMealString]: null
          };
          draft.menuItemsWithModifierPromptsByMeal = {
            ...draft.menuItemsWithModifierPromptsByMeal,
            [requestMealId.dateMealString]: null
          };
        })
      );

      return this.menuService
        .getMenuAndQuantityRestrictions({
          meal_id: isServiceAppointment ? undefined : requestMealId.mealId,
          date: ticketDate
        })
        .pipe(
          tap(([menu, quantityRestrictions]) =>
            ctx.dispatch(
              new GetMenuSuccess(requestMealId, menu, quantityRestrictions)
            )
          )
        );
    }
  }

  @Action(GetMenuSuccess)
  getMenuSuccess(
    ctx: StateContext<MenuStateModel>,
    { requestMealId, menu, quantity_restrictions }: GetMenuSuccess
  ) {
    this.formatMenu(menu);
    const barcodeProducts = MenuStateHelper.getBarcodeProductsDictionary(menu);
    const menuWithPrompts = MenuStateHelper.formatMenuWithPrompt(menu);

    ctx.setState(
      produce((draft) => {
        draft.menu = menu;
        draft.quantity_restrictions[requestMealId.date] = quantity_restrictions;
        draft.menu_date = requestMealId.date;
        draft.menu_selected_date_meal_id = requestMealId.dateMealString;
        draft.menuItemsWithModifierPrompts = menuWithPrompts;
        draft.barcodeProducts = barcodeProducts;

        GeneralHelper.updateObject(
          draft,
          'menu_by_meal',
          requestMealId.dateMealString,
          menu
        );

        GeneralHelper.updateObject(
          draft,
          'menuItemsWithModifierPromptsByMeal',
          requestMealId.dateMealString,
          menuWithPrompts
        );

        GeneralHelper.updateObject(
          draft,
          'barcodeProductsByMeal',
          requestMealId.dateMealString,
          barcodeProducts
        );
      })
    );
  }

  //Call the request to get the Menu Tally
  @Action(GetMenuTally, { cancelUncompleted: true })
  getMenuTally(
    ctx: StateContext<MenuStateModel>,
    { diner_id, meal_id, date }: GetMenuTally
  ) {
    const { menu_tally } = ctx.getState();
    const isMenuTallyLoaded = _.has(menu_tally, [
      diner_id,
      `${date}_${meal_id}`
    ]);

    if (!isMenuTallyLoaded) {
      return this.dinerService
        .getMenuTally(diner_id, meal_id, date)
        .pipe(
          tap(({ food_details, meal_id: mealId }) =>
            ctx.dispatch(
              new GetMenuTallySuccess(
                food_details,
                diner_id,
                `${date}_${mealId}`
              )
            )
          )
        );
    }
  }

  @Action(GetMenuTallySuccess)
  getMenuTallySuccess(
    ctx: StateContext<MenuStateModel>,
    { food_details, diner_id, date_meal_id }: GetMenuTallySuccess
  ) {
    const { menu_items, also_available } = this.formatMenuTally(
      ctx,
      food_details
    );

    const menuTallyByMeal = {
      menu_items,
      also_available
    };

    ctx.setState(
      produce((draft) => {
        if (!(diner_id in draft.menu_tally)) {
          draft.menu_tally[diner_id] = {
            [date_meal_id]: menuTallyByMeal
          };
          return;
        }

        draft.menu_tally[diner_id] = {
          ...draft.menu_tally[diner_id],
          [date_meal_id]: menuTallyByMeal
        };
      })
    );
  }

  //#endregion

  //#region Macro Grid Handler

  //Set Macro Grid View
  @Action(UpdateMacroGridView)
  updateMacroGridView(
    { dispatch, patchState, getState }: StateContext<MenuStateModel>,
    { macro_grid_view }: UpdateMacroGridView
  ) {
    patchState({ macro_grid_view });
    if (macro_grid_view === MacroGridView.ITEMS) {
      dispatch(new ResetMacroGrid());
    } else if (macro_grid_view === MacroGridView.MAIN) {
      dispatch(new UpdateSelectedMacroGrid(getState().default_macro_grid));
    }
  }

  //Set selectedMacroGrid state
  @Action(UpdateSelectedMacroGrid)
  updateSelectedMacroGrid(
    { getState, patchState, setState }: StateContext<MenuStateModel>,
    { macro_grid_item }: UpdateSelectedMacroGrid
  ) {
    const state = getState();
    const currentIndex = state.macro_stack.findIndex(
      (i) => i.id === macro_grid_item.id
    );
    if (
      currentIndex >= 0 ||
      state.default_macro_grid?.id === macro_grid_item?.id
    ) {
      patchState({
        macro_stack: state.macro_stack.filter(
          (i, index) => index <= currentIndex
        ),
        selected_macro_grid: macro_grid_item
      });
      return;
    }
    setState(
      patch({
        macro_stack: append([macro_grid_item]),
        selected_macro_grid: macro_grid_item
      })
    );
  }

  //Set selectedModifierMacroGrid state
  @Action(UpdateSelectedModifierMacroGrid)
  updateSelectedModifierMacroGrid(
    { getState, setState }: StateContext<MenuStateModel>,
    { macro_grid_item }: UpdateSelectedModifierMacroGrid
  ) {
    const state = getState();
    const currentIndex = state.modifier_macro_stack.findIndex(
      (i) => i.id === macro_grid_item.id
    );
    const isRemove =
      currentIndex >= 0 ||
      state.default_modifier_macro_grid.id === macro_grid_item.id;

    setState(
      patch({
        modifier_macro_stack: isRemove
          ? state.modifier_macro_stack.filter(
              (i, index) => index <= currentIndex
            )
          : append([<MacroGridItem>macro_grid_item]),
        selected_modifier_macro_grid: macro_grid_item
      })
    );
  }

  //Set selectedMacroGrid and macro_stack state
  @Action(BackToSelectedMacroGrid)
  backToSelectedMacroGrid({
    getState,
    patchState
  }: StateContext<MenuStateModel>) {
    const state = getState();
    if (state.isModifierGridVisible) {
      const modifier_macro_stack = _.cloneDeep(state.modifier_macro_stack);
      modifier_macro_stack.pop();
      const previousMacroGrid = _.last(modifier_macro_stack);
      patchState({
        modifier_macro_stack,
        macro_stack: [],
        selected_modifier_macro_grid:
          previousMacroGrid || state.default_modifier_macro_grid,
        selected_macro_grid: state.default_macro_grid
      });
    } else {
      const macro_stack = _.cloneDeep(state.macro_stack);
      macro_stack.pop();
      const previousMacroGrid = _.last(macro_stack);
      patchState({
        macro_stack,
        modifier_macro_stack: [],
        selected_modifier_macro_grid: state.default_modifier_macro_grid,
        selected_macro_grid: previousMacroGrid || state.default_macro_grid
      });
    }
  }

  //Reset selectedMacroGrid to the default macro grid and macro_stack to empty
  @Action(ResetMacroGrid)
  resetMacroGrid({ getState, patchState }: StateContext<MenuStateModel>) {
    const state = getState();
    patchState({
      selected_macro_grid: state.default_macro_grid,
      selected_modifier_macro_grid: state.default_modifier_macro_grid,
      macro_stack: [],
      modifier_macro_stack: []
    });
  }

  @Action(ShowModifierGrid)
  setModifierGridVisibility(
    { patchState }: StateContext<MenuStateModel>,
    { isModifierGridVisible }: ShowModifierGrid
  ) {
    patchState({ isModifierGridVisible });
  }

  @Action(ResetGridView)
  resetGridView(ctx: StateContext<MenuStateModel>) {
    const currentState = ctx.getState();
    const state = produce(ctx.getState(), (draft) => {
      draft.selected_macro_grid = currentState.default_macro_grid;
      draft.selected_modifier_macro_grid =
        currentState.default_modifier_macro_grid;
      draft.macro_stack = [];
      draft.modifier_macro_stack = [];
      draft.macro_grid_view = MacroGridView.MAIN;
    });
    ctx.setState(state);
  }

  /** This method covers the scenario of creating a new ticket in the future.
   * Previously, we only covered getting meals for today.
   */
  @Action(CreateTicketSuccess)
  changeToCorrectMenuWhenCreatingNewTicket(
    ctx: StateContext<MenuStateModel>,
    payload: CreateTicketSuccess
  ) {
    const mealId = payload.ticket.meal_id;
    const ticketDate = payload.ticket?.ticket_date;
    if (mealId && ticketDate) {
      const isMenuIncorrect =
        ticketDate.concat('_', mealId.toString()) !==
        ctx.getState().menu_selected_date_meal_id;
      if (isMenuIncorrect) {
        ctx.dispatch(new UpdateSelectedMealId(mealId, ticketDate));
      }
    }
  }

  /** Based on the acceptance criteria of ticket DEF-4675. */
  @Action(RemoveTicketSuccess)
  resetSelectedMeal(ctx: StateContext<MenuStateModel>) {
    const { currentMealId, location } =
      this.store.selectSnapshot(LOCATION_STATE_TOKEN);
    const selected_meal_id: number = LocationStateHelper.getMealId(
      currentMealId,
      location.type
    );
    ctx.patchState({
      selected_meal_id
    });
  }
  //#endregion

  //#region Formatters

  private formatMacroGrids(macroGrid: MacroGrid) {
    if (!macroGrid) {
      return;
    }

    // Only let grid items that should be displayed persist
    const { macro_grid_items } = macroGrid;
    macroGrid.macro_grid_items = MacroHelper.filterMacroGridItems(
      macroGrid,
      macro_grid_items
    ) as MacroGridItem[];
    return macroGrid;
  }

  @Action(SetSelectedMealId)
  setSelectedMealId(
    ctx: StateContext<MenuStateModel>,
    { mealId }: SetSelectedMealId
  ) {
    ctx.patchState({ selected_meal_id: mealId });
  }

  @Action(SelectNewSeat)
  resetToMainView(ctx: StateContext<MenuStateModel>) {
    ctx.patchState({
      macro_grid_view: MacroGridView.MAIN,
      isModifierGridVisible: false
    });
  }

  private formatMenu(menu: Menu): Menu {
    if (menu) {
      this.formatMenuList(menu, 'menu_items', MenuItemType.Food);
      this.formatMenuList(menu, 'also_available', MenuItemType.Food);
      this.formatMenuList(menu, 'products', MenuItemType.Product);
    }
    return menu;
  }

  private formatMenuList(menu: Menu, propName: string, type: MenuItemType) {
    if (menu && menu[propName]) {
      menu[propName] = _.unionBy(menu[propName], 'id');
      _.remove(menu[propName], ['name', null]);
      menu[propName].forEach((item) => {
        MenuHelper.addPriceTo(item);
        item.type = type;
        item.quantity = 1;
      });
    }
  }

  private formatMenuTally(
    ctx: StateContext<MenuStateModel>,
    tallyFoodDetails: MenuTallyFoodDetail[]
  ) {
    const menu = ctx.getState().menu;
    const currentMenuItems = menu?.menu_items || [];
    const currentAAMenuItems = menu?.also_available || [];
    const menuItems: MenuItem[] = [];
    const aaMenuItems: MenuItem[] = [];

    tallyFoodDetails.forEach((item) => {
      const foodIdString = String(item.food_id);

      let menuItem: MenuItem = {
        amount: item.amount,
        category_id: parseInt(String(item.category_id), 10),
        name: item.display_name,
        id: parseInt(foodIdString, 10),
        food_id: parseInt(foodIdString, 10),
        is_dislike: Boolean(item.is_dislike),
        is_like: Boolean(item.is_like),
        unit: item.unit,
        type: MenuItemType.Food,
        price_type: item.price_type || PriceType.FIXED,
        modifier: item.modifier
      };

      const existingMenuItem = (
        item.is_from_aa_menu ? currentAAMenuItems : currentMenuItems
      ).find((i) => i.food_id === item.food_id);

      if (existingMenuItem) {
        menuItem = Object.assign({}, existingMenuItem, menuItem);
      }

      const courseByMenuItem = menu.categories.find(
        (cat) => cat.id === menuItem.category_id
      )?.course;

      MenuHelper.addPriceTo(menuItem, courseByMenuItem);

      if (item.is_from_aa_menu) {
        aaMenuItems.push(menuItem);
      } else {
        menuItems.push(menuItem);
      }
    });

    return {
      menu_items: _.uniqBy(menuItems, 'id'),
      also_available: _.uniqBy(aaMenuItems, 'id')
    };
  }

  private getRequestMealId(date: string, mealId?: number): RequestMealId {
    if (mealId) {
      return {
        date,
        mealId,
        dateMealString: date.concat('_', mealId.toString())
      };
    }
    const { currentMealId: current_meal_id, location } =
      this.store.selectSnapshot(LOCATION_STATE_TOKEN);

    const currentMealId: number = LocationStateHelper.getMealId(
      current_meal_id,
      location.type
    );
    const ticketState = this.store.selectSnapshot(TICKETS_STATE_TOKEN),
      selectedTicket =
        ticketState.tickets.byId &&
        ticketState.tickets.byId[ticketState.selectedTicket.data],
      selectedMealId = selectedTicket?.meal_id || currentMealId;
    return {
      date,
      dateMealString: date.concat('_', selectedMealId.toString()),
      mealId: selectedMealId
    };
  }
  //#endregion

  private static kioskMacroGridFilter(macroGrid: MacroGrid) {
    return macroGrid.macro_grid_items.filter(
      (x) =>
        !x.product_details ||
        x.product_details.price_type === PriceType.FIXED ||
        (x.product_details.price_type === PriceType.VARIABLE &&
          x.product_details.product_type === ProductType.GIFT_CARD)
    );
  }

  @Action(DeleteQuantityRestriction)
  deleteQuantityRestriction(
    ctx: StateContext<MenuStateModel>,
    { restriction }: DeleteQuantityRestriction
  ) {
    const date = restriction.restriction_date;
    ctx.setState(
      produce((draft) => {
        const isQuantityRestrictionExist = !!draft.quantity_restrictions[date];
        if (!isQuantityRestrictionExist) {
          return;
        }

        draft.quantity_restrictions[date] = draft.quantity_restrictions[
          date
        ].filter((i) => {
          const foodId = i.food_id,
            productId = i.product_id;
          if (foodId) {
            return foodId !== restriction.food_id;
          }

          if (productId) {
            return productId !== restriction.product_id;
          }

          return true;
        });
      })
    );
  }

  @Action(UpdateQuantityRestriction)
  updateQuantityRestriction(
    ctx: StateContext<MenuStateModel>,
    { restriction }: UpdateQuantityRestriction
  ) {
    const foodId = restriction.food_id,
      productId = restriction.product_id,
      date = restriction.restriction_date;
    ctx.setState(
      produce((draft) => {
        const isQuantityRestrictionExist = !!draft.quantity_restrictions[date];
        if (!isQuantityRestrictionExist) {
          return;
        }

        const index = draft.quantity_restrictions[date].findIndex((i) => {
          if (foodId) {
            return i.food_id === foodId;
          }

          if (productId) {
            return i.product_id === productId;
          }

          return false;
        });

        if (index > -1) {
          draft.quantity_restrictions[date][index] = {
            ...draft.quantity_restrictions[date][index],
            remaining_quantity: restriction.remaining_quantity,
            sold_out: restriction.sold_out
          };
        } // Restriction created
        else {
          const createdRestriction: PosQuantityRestriction = {
            restriction_date: restriction.restriction_date,
            food_id: restriction.food_id,
            product_id: restriction.product_id,
            remaining_quantity: restriction.remaining_quantity,
            sold_out: restriction.sold_out
          } as PosQuantityRestriction;

          draft.quantity_restrictions[date].push(createdRestriction);
        }
      })
    );
  }
}
