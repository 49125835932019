import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Action, State, StateContext, Store } from '@ngxs/store';
import {
  catchError,
  combineLatest,
  EMPTY,
  map,
  of,
  switchMap,
  tap
} from 'rxjs';
import { produce } from 'immer';
import _ from 'lodash';
//-------------------------------------
import {
  PaymentRequestType,
  PaymentType,
  RefundResponse,
  TicketTransaction
} from 'src/app/models';
import { TicketService } from 'src/app/services';
import { TransactionLookupService } from 'src/app/services/transaction/transaction.service';

import {
  AddRefundTransaction,
  ClearRefundState,
  DeleteRefundTicket,
  GetTransactions,
  RefundComplete,
  RefundError,
  RefundTicket,
  SelectTransaction,
  StartManualRefund
} from './transaction-lookup.action';
import { UpdateChargeAccountBalance } from '../diners/diners.action';
import { OpenCashDrawer } from '../app/app.action';

import {
  TransactionLookupStateModel,
  TRANSACTION_LOOKUP_STATE_TOKEN
} from './transaction-lookup.state.model';

@State<TransactionLookupStateModel>({
  name: TRANSACTION_LOOKUP_STATE_TOKEN,
  defaults: {
    transactions: {
      isLoaded: false,
      isProcessing: false,
      data: []
    },
    selectedTransaction: null,
    originalTicket: null,
    refundTicket: null,
    pos_operator_id: null,
    isManualRefundModalOpen: false
  }
})
@Injectable()
export class TransactionLookupState {
  constructor(
    private readonly transactionService: TransactionLookupService,
    private readonly ticketService: TicketService,
    private readonly store: Store
  ) {}

  @Action(GetTransactions, { cancelUncompleted: true })
  getTransactions(
    ctx: StateContext<TransactionLookupStateModel>,
    { filters }: GetTransactions
  ) {
    ctx.setState(
      produce((draft) => {
        draft.transactions.isProcessing = true;
        draft.transactions.isLoaded = false;
        draft.transactions.data = [];
      })
    );

    let query = [
      this.transactionService.getTransactions(filters),
      this.transactionService.getMealPlanTransactions(filters)
    ];
    if (filters.type) {
      query = [this.transactionService.getTransactions(filters)];
      if (filters.type === PaymentType.MealPlan) {
        query = [
          of([]),
          this.transactionService.getMealPlanTransactions(filters)
        ];
      }
    }

    return combineLatest(query).pipe(
      catchError(() => {
        ctx.setState(
          produce((draft) => {
            draft.transactions.isProcessing = false;
          })
        );
        return EMPTY;
      }),
      map(([transactions, mealPlanTransactions]) => {
        const result = transactions.concat(
          (mealPlanTransactions || []).map((i) => ({
            ...i,
            is_meal_plan: true
          }))
        );
        return _.orderBy(result, 'id', 'desc');
      }),
      tap((transactions) => {
        ctx.setState(
          produce((draft) => {
            draft.transactions.isProcessing = false;
            draft.transactions.data = transactions;
            draft.transactions.isLoaded = true;
          })
        );
      })
    );
  }

  @Action(SelectTransaction)
  selectTransaction(
    ctx: StateContext<TransactionLookupStateModel>,
    { transactionId }: SelectTransaction
  ) {
    if (transactionId) {
      const transactions = ctx.getState().transactions.data;
      const selectedTransaction = transactions.find(
        (t) => t.id === transactionId
      );
      return this.ticketService.getTicket(selectedTransaction.ticket_uuid).pipe(
        tap((ticket) => {
          ctx.setState(
            produce((draft: TransactionLookupStateModel) => {
              draft.originalTicket = ticket;
              draft.selectedTransaction = transactionId;
            })
          );
        })
      );
    } else {
      ctx.patchState({ selectedTransaction: null, originalTicket: null });
    }
  }

  @Action(RefundTicket)
  refundTicket(
    ctx: StateContext<TransactionLookupStateModel>,
    { ticketItems, operatorId }: RefundTicket
  ) {
    const state = ctx.getState(),
      refundedTicketItemStatus =
        'Ticket Item already has refund in progress. Device Ticket UUID in error.';

    if (!state.originalTicket) {
      return;
    }

    const ticket_uuids: string[] = ticketItems?.length
      ? ticketItems.map((ti) => ti.device_ticket_item_uuid)
      : [];

    ctx.patchState({ pos_operator_id: operatorId });

    return this.transactionService
      .refund(state.originalTicket.device_ticket_uuid, operatorId, ticket_uuids)
      .pipe(
        switchMap((response: RefundResponse) =>
          this.handleRefundResponse(ctx, response)
        ),
        catchError(({ error }: HttpErrorResponse) => {
          if (error.status === refundedTicketItemStatus) {
            return ctx.dispatch(
              new DeleteRefundTicket(error.message, {
                ticketItems,
                operatorId
              })
            );
          }
          //When a refund of a Pos::Transaction::CreditStripe throws an error at the Gateway level. Touch Backend will return a 502 and an error message.
          const errorMessage =
            error.status === 502
              ? `${error.message}`
              : `${error.status}<br/><br/>Please contact MealSuite Support and reference the above error for assistance.`;

          return ctx.dispatch(new RefundError(errorMessage));
        })
      );
  }

  @Action(AddRefundTransaction)
  addRefundTransaction(
    ctx: StateContext<TransactionLookupStateModel>,
    { refundTransactionRequest }: AddRefundTransaction
  ) {
    const state = ctx.getState();
    return this.transactionService
      .addRefundTransaction(
        state.refundTicket.refund_ticket_id,
        refundTransactionRequest,
        state.pos_operator_id
      )
      .pipe(
        tap((response: RefundResponse) => {
          this.handleRefundResponse(ctx, response);
        })
      );
  }

  @Action(RefundComplete)
  onRefundComplete(
    ctx: StateContext<TransactionLookupStateModel>,
    {}: RefundComplete
  ) {
    const state = ctx.getState();
    if (!state.originalTicket) {
      return;
    }

    return this.ticketService
      .getTicket(state.originalTicket.device_ticket_uuid)
      .pipe(
        tap((ticket) => {
          ctx.setState(
            produce((draft: TransactionLookupStateModel) => {
              draft.originalTicket = ticket;
            })
          );
        })
      );
  }

  @Action(ClearRefundState)
  onClearRefundState(
    ctx: StateContext<TransactionLookupStateModel>,
    {}: ClearRefundState
  ) {
    ctx.patchState({
      refundTicket: null,
      pos_operator_id: null,
      isManualRefundModalOpen: false
    });
  }

  @Action(DeleteRefundTicket)
  deleteRefundTicket(
    ctx: StateContext<TransactionLookupState>,
    { device_ticket_uuid, refundParams }: DeleteRefundTicket
  ) {
    return this.ticketService.deleteRefundTicket(device_ticket_uuid).pipe(
      tap(() => {
        if (refundParams) {
          this.store.dispatch(
            new RefundTicket(refundParams.ticketItems, refundParams.operatorId)
          );
        }
      })
    );
  }

  handleRefundResponse(
    ctx: StateContext<TransactionLookupStateModel>,
    response: RefundResponse
  ) {
    const state = ctx.getState();
    const actions = [];
    switch (response.status) {
      case 'in_progress':
      case 'pos_payment_location_missing':
      case 'giftcard_confirmation_required':
        response.available_refund_tenders = this.reduceRefundTenders(response);
        if (!state.refundTicket) {
          actions.push(new StartManualRefund());
          ctx.patchState({ isManualRefundModalOpen: true });
        }
        ctx.patchState({ refundTicket: response });
        break;
      case 'over_payment':
        actions.push(
          new RefundError(
            `${response.error}<br/><br/>Please contact MealSuite Support and reference the above error for assistance.`
          )
        );
        break;
      case 'completed':
        if (state.isManualRefundModalOpen) {
          ctx.patchState({ refundTicket: response });
        } else {
          ctx.patchState({ refundTicket: null });
        }

        const existing_transactions = response.existing_transactions;

        //Ensure existing_actions array is not null or empty
        const isValidExistingTransactions =
          existing_transactions && existing_transactions?.length;

        if (isValidExistingTransactions) {
          const chargeAccountRefund = existing_transactions.find(
            (transaction) =>
              transaction.type === PaymentRequestType.CHARGE_ACCOUNT &&
              !!transaction.pos_charge_account_id
          );

          if (chargeAccountRefund) {
            actions.push(
              new UpdateChargeAccountBalance(
                chargeAccountRefund.diner_id,
                <TicketTransaction>chargeAccountRefund
              )
            );
          }

          const cashRefund = existing_transactions.find(
            (transaction) => transaction.type === PaymentRequestType.CASH
          );

          //DEF-4075: Open the cash drawer on successful cash payment refunds
          if (cashRefund) {
            actions.push(new OpenCashDrawer(state.pos_operator_id, false));
          }
        }

        actions.push(new RefundComplete());
        break;
      default:
        actions.push(
          new RefundError(
            'Unknown status<br/><br/>Please contact MealSuite Support and reference the above error for assistance.'
          )
        );
    }
    return ctx.dispatch(actions);
  }

  //Merge transactions by type and add amounts
  //Cash: All transactions
  //CreditManual: All Transactions
  //CreditTranCloud: All Transactions - TODO: get a new attribute from BE to differentiate between different CC cards used.
  //Gift Card: Merge all Gift Card Transactions where the identification number is the same
  //Bill To Room: Merge all BTR Transactions where the diner name is the same - TODO: Get Diner ID from BE and use that instead in case it's different diners with the same name
  //Charge Account: Merge all CA Transactions where department_id is present and is the same
  //Charge To Department: Merge all CTD Transactions where pos_charge_account_id is present and is the same
  private reduceRefundTenders(response: RefundResponse) {
    //Calculate new maximum amounts per tender by adding the amount on existing transactions
    const amounts: Object = {};
    if (
      response.existing_transactions &&
      response.existing_transactions.length
    ) {
      response.existing_transactions.forEach((et: any) => {
        if (amounts.hasOwnProperty(et.type)) {
          amounts[et.type] += parseFloat(et.amount);
        } else {
          amounts[et.type] = parseFloat(et.amount);
        }
      });
    }

    return response.available_refund_tenders.reduce((list, obj) => {
      let found = false;
      for (const item of list) {
        if (
          item.type === obj.type &&
          (obj.type === PaymentRequestType.CASH ||
            obj.type === PaymentRequestType.CREDIT_CARD ||
            (obj.type === PaymentRequestType.CREDIT_CARD_TRANCLOUD &&
              item.payment_gateway_transaction_id ===
                obj.payment_gateway_transaction_id) ||
            (obj.type === PaymentRequestType.GIFT_CARD &&
              item.gift_card_identification_number ===
                obj.gift_card_identification_number) ||
            (obj.type === PaymentRequestType.BILL_TO_ROOM &&
              item.diner_name === obj.diner_name) ||
            (obj.type === PaymentRequestType.CHARGE_ACCOUNT &&
              ((item.department_id &&
                item.department_id === obj.department_id) ||
                (item.pos_charge_account_id &&
                  item.pos_charge_account_id === obj.pos_charge_account_id))))
        ) {
          item.amount = parseFloat(item.amount) + parseFloat(obj.amount);
          found = true;
          break;
        }
      }

      if (!found) {
        //When adding a new Tender, add values to calculate new max amounts.
        if (amounts.hasOwnProperty(obj.type)) {
          obj.amount = `${
            parseFloat(obj.amount) + parseFloat(amounts[obj.type])
          }`;
        }
        list.push(obj);
      }

      return list;
    }, []);
  }
}
