import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';
//----------------------------------------------
import { ModalService } from 'src/app/services';
import {
  DeviceRegistrationPageError,
  HttpRequestError,
  LoginPageError,
  AppliedTicketDiscountError,
  RequestError,
  ResetError,
  ExceptionError
} from './error.action';
import { CreatePOSProductError } from 'src/app/store/pos-manager';
import { ResetWaitingTicketItem } from 'src/app/store/ticket-items/ticket-items.action';
import { GetDinerByBarcodeError } from 'src/app/store/self-service/self-service.action';
import { SwitchDeviceLocationError } from 'src/app/store/app/app.action';
import {
  AddTicketItemError,
  CloseTicketError,
  CreateTicketError,
  FireTicketAndCloseError,
  RemoveDinerFromTicketError,
  SetupTakeoutOrderError
} from 'src/app/store/tickets/tickets.action';
import { PayOnPickupError } from 'src/app/store/payment/payment.action';

export interface ErrorStateModel {
  errorMessage: string;
}

const ERROR_STATE_TOKEN = new StateToken<ErrorStateModel>('error');

@State({
  name: ERROR_STATE_TOKEN,
  defaults: {
    errorMessage: ''
  }
})
@Injectable()
export class ErrorState {
  constructor(
    private readonly toastController: ToastController,
    private readonly modalService: ModalService
  ) {}

  //#region Selectors

  @Selector()
  static getErrorMessage(state: ErrorStateModel) {
    return state.errorMessage;
  }

  //#endregion

  //#region Actions
  @Action([DeviceRegistrationPageError, LoginPageError, RequestError])
  deviceRegistrationPageError(
    { patchState }: StateContext<ErrorStateModel>,
    { errorCode }
  ) {
    patchState({ errorMessage: errorCode });
  }

  @Action(ResetError)
  resetError({ patchState }: StateContext<ErrorStateModel>) {
    patchState({
      errorMessage: null
    });
  }

  @Action([
    FireTicketAndCloseError,
    CloseTicketError,
    GetDinerByBarcodeError,
    SwitchDeviceLocationError,
    SetupTakeoutOrderError,
    PayOnPickupError,
    CreateTicketError,
    CreatePOSProductError
  ])
  async toastMessageError(
    _ctx: StateContext<ErrorStateModel>,
    { errorMessage }: FireTicketAndCloseError
  ) {
    this.modalService.closeLoadingModal();

    const toast = await this.toastController.create({
      message: errorMessage,
      duration: 3000,
      color: 'danger',
      position: 'bottom'
    });

    await toast.present();
  }

  @Action([
    HttpRequestError,
    RemoveDinerFromTicketError,
    AppliedTicketDiscountError,
    ExceptionError
  ])
  alertError(
    _ctx: StateContext<ErrorStateModel>,
    { errorMessage }: HttpRequestError
  ) {
    this.modalService.alertErrorMessage(errorMessage);
  }

  @Action(AddTicketItemError)
  addTicketItemError(
    ctx: StateContext<ErrorStateModel>,
    action: AddTicketItemError
  ) {
    ctx.dispatch(new ResetWaitingTicketItem());
    const errorMessage = action.httpErrorResponse.error.message;
    if (typeof errorMessage === 'string') {
      this.modalService.alertErrorMessage(errorMessage);
    }
  }

  //#endregion
}
