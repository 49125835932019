import {
  DeliveryType,
  PickupDeliveryFeeType,
  TicketStatus
} from 'src/app/models';

interface PickupDeliveryFeeMapperValue {
  type: PickupDeliveryFeeType;
  name: string;
}

export class TicketConstant {
  static readonly ABLE_ADD_NEW_ITEM_STATUS = [
    TicketStatus.EMPTY,
    TicketStatus.IN_PROGRESS,
    TicketStatus.FIRED
  ];

  static readonly PICKUP_DELIVERY_FEE_MAPPER: Record<
    DeliveryType,
    PickupDeliveryFeeMapperValue
  > = {
    [DeliveryType.DELIVERY]: { name: 'Delivery Fee', type: 'delivery' },
    [DeliveryType.TO_GO]: { name: 'Takeout Fee', type: 'pick_up' },
    [DeliveryType.PICK_UP]: { name: 'Pickup Fee', type: 'pick_up' },
    [DeliveryType.TO_TABLE]: null,
    [DeliveryType.TO_ROOM]: null,
    [DeliveryType.TO_SEAT]: null
  };
}
