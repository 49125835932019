import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { v4 as uuidv4 } from 'uuid';
import { Observable, map } from 'rxjs';
//==============================================================================
import { CommonConstant, PosTenantType } from 'src/app/constants';
import {
  PosAlertType,
  DeviceData,
  RegisterDeviceResponse,
  BaseData
} from 'src/app/models';
import { ErrorService } from 'src/app/services/error/error.service';
import { ApiType, PosConfig } from 'src/app/pos.config';
import { AppUtil } from 'src/app/utils/app.util';

@Injectable({
  providedIn: 'root'
})
export class DeviceService {
  private requestHeaders = {
    headers: new HttpHeaders({
      Authorization: PosConfig.REGISTER_DEVICE_TOKEN
    })
  };

  constructor(
    private http: HttpClient,
    private platform: Platform,
    private readonly errorService: ErrorService
  ) {}

  get isDevice(): boolean {
    return this.isAndroidDevice || this.isIOS; //ON_DEVICE || this.electron.isElectronApp;
  }

  get isAndroidDevice() {
    return this.platform.is('android') && !this.platform.is('mobileweb');
  }

  get isIOS() {
    return this.platform.is('ios') && !this.platform.is('mobileweb');
  }

  supportAlerts(payload: {
    alert_type: PosAlertType;
    alert_message: string;
  }): Observable<{ success: boolean }> {
    const { alert_type, alert_message } = payload;

    return this.http.post<{ success: boolean }>('support_alerts', {
      alert_type,
      alert_message
    });
  }

  getDevice(
    domain: string,
    hardware_id: string,
    operating_system: string
  ): Observable<RegisterDeviceResponse> {
    return this.http.post<RegisterDeviceResponse>(
      `${domain}/api/devices/v1/register_device`,
      {},
      {
        headers: this.requestHeaders.headers,
        params: { hardware_id, operating_system }
      }
    );
  }

  getUuid() {
    let uuid = localStorage.getItem(CommonConstant.UUID_KEY);
    if (!uuid) {
      uuid = uuidv4();
      localStorage.setItem(CommonConstant.UUID_KEY, uuid);
    }
    return { uuid };
  }

  transferDeviceLocation(device_id: number, pos_location_id: number) {
    return this.http
      .put<{ device: DeviceData }>(`devices/${device_id}`, {
        pos_location_id
      })
      .pipe(this.errorService.retryPipe(ApiType.Mutate));
  }

  updateDevice(device_id: number, device: Partial<DeviceData>) {
    return this.http
      .put<{ device: DeviceData }>(`devices/${device_id}`, device)
      .pipe(
        this.errorService.retryPipe(ApiType.Mutate),
        map((response) => response.device)
      );
  }

  getAccountsForTenant(
    hardware_id: string,
    newTenant: string,
    currentTenant: string
  ): Observable<BaseData[]> {
    const domainUrl = AppUtil.configHost(newTenant as PosTenantType);

    return this.http
      .get<{ accounts: BaseData[] }>(
        `${domainUrl}/api/devices/v1/accounts?hardware_id=${hardware_id}&device_tenant=${currentTenant}`,
        {
          headers: this.requestHeaders.headers
        }
      )
      .pipe(
        this.errorService.retryPipe(ApiType.Query),
        map((response) => response.accounts)
      );
  }

  getPosLocations(
    hardware_id: string,
    newTenant: string,
    currentTenant: string,
    facility_id: number
  ): Observable<BaseData[]> {
    const domainUrl = AppUtil.configHost(newTenant as PosTenantType);

    return this.http
      .get<{ pos_locations: BaseData[] }>(
        `${domainUrl}/api/devices/v1/accounts/${facility_id}/pos_locations?hardware_id=${hardware_id}&device_tenant=${currentTenant}`,
        {
          headers: this.requestHeaders.headers
        }
      )
      .pipe(
        this.errorService.retryPipe(ApiType.Query),
        map((response) => response.pos_locations)
      );
  }

  selfDeviceRegister(
    accountId: string,
    quick_registration_code: string,
    hardware_id: string
  ): Observable<string> {
    const [facility_id, tenant] =
      AppUtil.getTenantAndFacilityFromAccountId(accountId);
    const baseUrl = AppUtil.configHost(tenant);
    const url = `${baseUrl}/api/devices/v1/register_device/quick_registration`;

    return this.http
      .put<{
        status: string;
        success: boolean;
      }>(
        url,
        {},
        {
          headers: {
            Authorization: PosConfig.REGISTER_DEVICE_TOKEN
          },
          params: {
            hardware_id,
            facility_id,
            quick_registration_code
          }
        }
      )
      .pipe(map((res) => res.status));
  }
}
