import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Action, State, StateContext, StateToken, Store } from '@ngxs/store';
import { produce } from 'immer';
import {
  Observable,
  Subject,
  catchError,
  concat,
  forkJoin,
  map,
  mergeMap,
  of,
  takeLast,
  takeUntil,
  tap
} from 'rxjs';
import _ from 'lodash';
//----------------------------------------------
import {
  AddTicketItemResult,
  MacroGridView,
  NormalizedObject,
  Ticket,
  TicketItem,
  TicketItemRequest,
  TicketItemStatus
} from 'src/app/models';
import { NormalizedTicketItem } from 'src/app/models/normalized-models/normalized-ticket-item.model';
import { TicketService } from 'src/app/services';
import { GeneralHelper, NormalizrHelper } from 'src/app/helpers';
import { LogRocketProvider, LOG_ROCKET_CUSTOM_EVENTS } from 'src/app/providers';

import {
  AddTicketItems,
  FireTicketSuccess,
  GetTicketsSuccess,
  RemoveAllSskTicketItems,
  RemoveTicketItem,
  RemoveTicketItemSuccess,
  RemoveTicketSuccess,
  UpdateLocalTicketRelationships,
  UpdateTicketSuccess
} from '../tickets/tickets.action';
import {
  AddChildItem,
  AddTicketItem,
  AddTicketItemDiscount,
  RemoveAllModifiersBelongTicketItem,
  RemoveTicketItemDiscount,
  VoidTicketItemSuccess,
  ResetWaitingTicketItem,
  SelectTicketItem,
  UpdateTicketItem,
  UpdateTicketItemSuccess,
  VoidTicketItem,
  AddTicketItemWithPrompts,
  AddTicketItemDiscountError,
  AddTicketItemDone,
  AddPickupDeliveryFeeToTicket
} from './ticket-items.action';
import { ShowModifierGrid, UpdateMacroGridView } from '../menu/menu.action';
import { APP_STATE_TOKEN } from 'src/app/store/app/app.state.model';
import { SelectNewSeat } from 'src/app/store/seats/seats.action';
import { TICKETS_STATE_TOKEN } from 'src/app/store/tickets/tickets.state';
import {
  FireCourseNotificationEvent,
  FireCourseSeatNotificationEvent,
  FireTableNotificationEvent,
  FireTicketItemNotificationEvent,
  FireTicketNotificationEvent
} from 'src/app/store/websocket.actions';
import { SEATS_STATE_TOKEN } from 'src/app/store/seats/seats.state';
import { TABLES_STATE_TOKEN } from 'src/app/store/tables/tables.state.model';
import { RemoveSskTicketItems } from 'src/app/store/self-service/self-service.action';
import { ExceptionError } from 'src/app/store/error/error.action';

type WaitingTicketItem = TicketItemRequest & { device_ticket_uuid: string };

export interface TicketItemsStateModel {
  ticketItems: NormalizedObject<NormalizedTicketItem>;
  selectedTicketItem: string;
  waitingTicketItem: WaitingTicketItem[];
}

export const TICKET_ITEMS_STATE_TOKEN = new StateToken<TicketItemsStateModel>(
  'ticket_items'
);

@State({
  name: TICKET_ITEMS_STATE_TOKEN,
  defaults: {
    ticketItems: {
      byId: {},
      allIds: []
    },
    selectedTicketItem: null,
    waitingTicketItem: []
  }
})
@Injectable()
export class TicketItemsState {
  constructor(
    private store: Store,
    private modalController: ModalController,
    private ticketService: TicketService,
    private logRocketProvider: LogRocketProvider
  ) {}

  @Action([GetTicketsSuccess, UpdateLocalTicketRelationships])
  getTicketsSuccess(
    ctx: StateContext<TicketItemsStateModel>,
    payload: GetTicketsSuccess | UpdateLocalTicketRelationships
  ) {
    const normalizedTicketItems = payload.entities.ticket_items;
    if (normalizedTicketItems) {
      const ticketItems = Object.values(normalizedTicketItems);
      Object.keys(normalizedTicketItems).forEach((key) => {
        const childItems =
          ticketItems.filter((i) => i.parent_uuid === key) || [];
        if (childItems.length) {
          normalizedTicketItems[key].ticket_items = childItems.map(
            (i) => i.device_ticket_item_uuid
          );
        }
      });

      ctx.setState(
        produce((draft: TicketItemsStateModel) => {
          draft.ticketItems = {
            byId: {
              ...draft.ticketItems.byId,
              ...normalizedTicketItems
            },
            allIds: _.union(
              draft.ticketItems.allIds,
              Object.keys(normalizedTicketItems)
            )
          };
        })
      );
    }
  }

  @Action(SelectTicketItem)
  selectTicketItem(
    ctx: StateContext<TicketItemsStateModel>,
    { ticketItemId }: SelectTicketItem
  ) {
    ctx.setState(
      produce((draft: TicketItemsStateModel) => {
        if (!draft.ticketItems.byId) {
          return;
        }

        if (
          draft.selectedTicketItem &&
          draft.ticketItems.byId[draft.selectedTicketItem]
        ) {
          draft.ticketItems.byId[draft.selectedTicketItem].local_attributes =
            draft.ticketItems.byId[draft.selectedTicketItem].local_attributes ||
            {};
          draft.ticketItems.byId[
            draft.selectedTicketItem
          ].local_attributes.selected = false;
        }
        draft.selectedTicketItem = ticketItemId;
        if (ticketItemId && draft.ticketItems.byId[ticketItemId]) {
          draft.ticketItems.byId[ticketItemId].local_attributes =
            draft.ticketItems.byId[ticketItemId].local_attributes || {};
          draft.ticketItems.byId[ticketItemId].local_attributes.selected = true;
        }
      })
    );
  }

  @Action(ResetWaitingTicketItem)
  resetWaitingTicketItem(
    ctx: StateContext<TicketItemsStateModel>,
    { isRemove }: ResetWaitingTicketItem
  ) {
    ctx.setState(
      produce((draft) => {
        if (isRemove && draft.ticketItems.byId[draft.selectedTicketItem]) {
          if (
            draft.ticketItems.byId[draft.selectedTicketItem].local_attributes
          ) {
            draft.ticketItems.byId[
              draft.selectedTicketItem
            ].local_attributes.removed = false;
          }
        } else {
          draft.waitingTicketItem = [];
        }
      })
    );
  }

  @Action(AddTicketItemWithPrompts)
  addTicketItemWithPrompts(
    ctx: StateContext<TicketItemsStateModel>,
    { ticketItem, canBeCancelled }: AddTicketItemWithPrompts
  ) {
    const selectedTicketUuid =
      this.store.selectSnapshot(TICKETS_STATE_TOKEN).selectedTicket.data;
    const ticketItems: TicketItemRequest[] = Array(ticketItem.quantity)
        .fill(ticketItem)
        .map((ti: TicketItemRequest) => ({
          ...ti,
          device_ticket_item_uuid: GeneralHelper.generateUuid(),
          children_attributes: ti.children_attributes.map((child) => ({
            ...child,
            device_ticket_item_uuid: GeneralHelper.generateUuid()
          }))
        })),
      destroy$ = new Subject<void>(),
      // in quick service, user can add item with quantity, so still need to keep this logic
      // the add item should be added one by one to avoid issue with discount & meal plan (async update)
      result: AddTicketItemResult[] = [];

    let device_ticket_item_uuids = [];

    if (ticketItems && ticketItems.length) {
      device_ticket_item_uuids = ticketItems.map(
        (x) => x.device_ticket_item_uuid
      );
      ctx.patchState({
        waitingTicketItem: ctx.getState().waitingTicketItem.concat(
          ticketItems.reduce((prev, curr) => {
            if (!curr.parent_uuid) {
              prev.push({
                ...curr,
                device_ticket_uuid: selectedTicketUuid
              });
            }
            return prev;
          }, [] as WaitingTicketItem[])
        )
      });
    }

    return concat(
      ...this.ticketService.addTicketItems(ticketItems, destroy$, result)
    ).pipe(
      takeUntil(destroy$),
      takeLast(1),
      tap((res) => {
        const createdTicketItems: TicketItem[] = [];

        result.forEach((i) => {
          if (!i) {
            return;
          }

          const { ticket, ticket_item } = i;

          if (ticket && ticket_item) {
            ticket.outstanding_balance = Number(ticket.outstanding_balance);
            ticket_item.ticket_items = (
              ticket_item.ticket_items || ticket_item.child_ticket_items
            )?.map((c) => this.addTicketItemLocalAttributes(c));
            createdTicketItems.push(
              this.addTicketItemLocalAttributes(
                _.merge({}, ticketItem, ticket_item)
              )
            );
          }
        });

        const normalizeTicketItems =
          NormalizrHelper.normalizeTicketItems(createdTicketItems).entities
            .ticket_items;
        ctx.setState(
          produce((draft: TicketItemsStateModel) => {
            if (normalizeTicketItems) {
              draft.ticketItems = {
                byId: {
                  ...draft.ticketItems.byId,
                  ...normalizeTicketItems
                },
                allIds: _.union(
                  draft.ticketItems.allIds,
                  Object.keys(normalizeTicketItems)
                )
              };
            }
            draft.waitingTicketItem = draft.waitingTicketItem.filter(
              (x) =>
                !device_ticket_item_uuids ||
                !device_ticket_item_uuids.includes(x.device_ticket_item_uuid)
            );
          })
        );
        const newCalculations = result[result.length - 1].ticket;

        if (newCalculations) {
          ctx.dispatch(
            new AddTicketItems(
              newCalculations,
              res.ticket_items.map((i) => i.device_ticket_item_uuid)
            )
          );
        }

        const lastTicket = _.last(result).ticket;

        if (
          canBeCancelled &&
          result.some((i) => i.ticket_item?.status === TicketItemStatus.FIRED)
        ) {
          if (lastTicket) {
            ctx.dispatch(
              new UpdateTicketSuccess({
                ...lastTicket,
                can_be_cancelled: false
              })
            );
          }
        } else {
          // Need to update meal plan balance
          ctx.dispatch(
            new UpdateTicketSuccess({
              ...lastTicket
            })
          );
        }

        /** Since BE has returned all ticket items for updating the price of each ticket item that has been applied discount, we need to update the ticket items in the state. */
        ctx.dispatch(new UpdateTicketItemSuccess(res.ticket_items));
      })
    );
  }

  @Action(AddTicketItem)
  addTicketItem(
    ctx: StateContext<TicketItemsStateModel>,
    { ticketItem, canBeCancelled }: AddTicketItem
  ) {
    const selectedTicketUuid =
      this.store.selectSnapshot(TICKETS_STATE_TOKEN).selectedTicket.data;
    const ticketItems: TicketItemRequest[] = Array(ticketItem.quantity)
        .fill(ticketItem)
        .map((ti: TicketItemRequest) => ({
          device_ticket_item_uuid: GeneralHelper.generateUuid(),
          ...ti
        })),
      destroy$ = new Subject<void>();

    if (ticketItems?.length) {
      ctx.patchState({
        waitingTicketItem: ctx.getState().waitingTicketItem.concat(
          ticketItems.reduce((prev, curr) => {
            prev.push({
              ...curr,
              device_ticket_uuid: selectedTicketUuid
            });
            return prev;
          }, [] as WaitingTicketItem[])
        )
      });
    }

    return concat(
      ...this.ticketService.addTicketItems(ticketItems, destroy$)
    ).pipe(
      takeUntil(destroy$),
      takeLast(1),
      tap((res) => {
        const ticketsById =
          this.store.selectSnapshot(TICKETS_STATE_TOKEN).tickets.byId;
        const isIncludeTicket = ticketsById[res.ticket.device_ticket_uuid];

        if (isIncludeTicket) {
          ctx.dispatch([
            new AddTicketItemDone(ticketItem, res, canBeCancelled),
            new ResetWaitingTicketItem()
          ]);
        }
      })
    );
  }

  @Action(AddTicketItemDone)
  addTicketItemDone(
    ctx: StateContext<TicketItemsStateModel>,
    { addTicketResponse, ticketItemPayload, canBeCancelled }: AddTicketItemDone
  ) {
    const result = addTicketResponse.ticket_items;
    const createdTicketItems: TicketItem[] = _.cloneDeep(result)
      .filter(
        (i) =>
          !ctx.getState().ticketItems.allIds.includes(i.device_ticket_item_uuid)
      )
      .map((i) => ({
        ...i,
        local_attributes: { removed: false }
      }));

    const normalizeTicketItems =
        NormalizrHelper.normalizeTicketItems(createdTicketItems).entities
          .ticket_items,
      device_ticket_item_uuids: string[] = result.map(
        (i) => i.device_ticket_item_uuid
      );
    ctx.setState(
      produce((draft: TicketItemsStateModel) => {
        if (normalizeTicketItems) {
          draft.ticketItems = {
            byId: {
              ...draft.ticketItems.byId,
              ...normalizeTicketItems
            },
            allIds: _.union(
              draft.ticketItems.allIds,
              Object.keys(normalizeTicketItems)
            )
          };
        }
        draft.waitingTicketItem = draft.waitingTicketItem.filter(
          (x) =>
            !device_ticket_item_uuids ||
            !device_ticket_item_uuids.includes(x.device_ticket_item_uuid)
        );
      })
    );

    const newCalculations = addTicketResponse.ticket;
    if (newCalculations) {
      const parentTicketItemUuid = ticketItemPayload?.parent_uuid;
      if (!!parentTicketItemUuid) {
        ctx.dispatch([
          new AddChildItem(device_ticket_item_uuids, parentTicketItemUuid),
          // update new outstanding balance to ticket
          new AddTicketItems(newCalculations, device_ticket_item_uuids)
        ]);
      } else {
        //After ticket items are added to the store, update the selected ticket with the new data
        ctx.dispatch(
          new AddTicketItems(newCalculations, device_ticket_item_uuids)
        );
      }
    }

    const addedTicket = addTicketResponse.ticket;

    if (
      canBeCancelled &&
      result.some((i) => i.status === TicketItemStatus.FIRED)
    ) {
      ctx.dispatch(
        new UpdateTicketSuccess({
          ...addedTicket,
          can_be_cancelled: false
        })
      );
    } else {
      // Need to update meal plan balance
      ctx.dispatch(
        new UpdateTicketSuccess({
          ...addedTicket
        })
      );
    }

    /** Since BE has returned all ticket items for updating the price of each ticket item that has been applied discount, we need to update the ticket items in the state. */
    ctx.dispatch(new UpdateTicketItemSuccess(result));
  }

  @Action(AddChildItem)
  addChildItem(
    ctx: StateContext<TicketItemsStateModel>,
    { ticketItemsUuids, parentTicketItemUuid }: AddChildItem
  ) {
    ctx.setState(
      produce((draft: TicketItemsStateModel) => {
        const updatedTicketItemUuid =
          parentTicketItemUuid || draft.selectedTicketItem;
        if (
          !draft.ticketItems.byId ||
          !draft.ticketItems.byId[updatedTicketItemUuid]
        ) {
          return;
        }

        if (!draft.ticketItems.byId[updatedTicketItemUuid].ticket_items) {
          draft.ticketItems.byId[updatedTicketItemUuid].ticket_items = [];
        }
        draft.ticketItems.byId[updatedTicketItemUuid].ticket_items =
          ticketItemsUuids;
      })
    );
  }

  @Action(UpdateTicketItem)
  updateTicketItem(
    ctx: StateContext<TicketItemsStateModel>,
    { data }: UpdateTicketItem
  ) {
    const state = ctx.getState();
    return this.ticketService
      .updateTicketItem(state.selectedTicketItem, data)
      .pipe(
        tap(() => {
          ctx.setState(
            produce((draft: TicketItemsStateModel) => {
              draft.ticketItems.byId[draft.selectedTicketItem] = _.merge(
                draft.ticketItems.byId[draft.selectedTicketItem],
                data
              );
            })
          );
        })
      );
  }

  @Action(VoidTicketItem)
  voidTicketItem(
    ctx: StateContext<TicketItemsStateModel>,
    payload: VoidTicketItem
  ) {
    return this.removeTicketItemHandler(ctx, payload);
  }

  @Action(RemoveAllModifiersBelongTicketItem)
  removeAllModifiersBelongTicketItem(ctx: StateContext<TicketItemsStateModel>) {
    const state = ctx.getState();
    if (!state.selectedTicketItem) {
      return;
    }

    const selectedTicketItem = state.ticketItems.byId[state.selectedTicketItem];
    if (selectedTicketItem && selectedTicketItem.ticket_items.length > 0) {
      ctx.setState(
        produce((draft: TicketItemsStateModel) => {
          selectedTicketItem.ticket_items.forEach((item) => {
            if (draft.ticketItems.byId[item].local_attributes) {
              draft.ticketItems.byId[item].local_attributes.removed = true;
            }
          });
        })
      );

      ctx.dispatch([
        new RemoveTicketItem(selectedTicketItem.ticket_items),
        new SelectTicketItem(),
        new ShowModifierGrid(false),
        new UpdateMacroGridView(MacroGridView.MAIN)
      ]);
      return of(selectedTicketItem.ticket_items).pipe(
        mergeMap((ticket_item_uuids) =>
          forkJoin(
            ticket_item_uuids.map((t) => this.ticketService.cancelTicketItem(t))
          )
        ), //Merge and wait for all requests to be completed
        map((tickets) => tickets.filter((t) => !_.isNil(t))),
        tap((tickets) =>
          ctx.dispatch(
            new RemoveTicketItemSuccess(
              _.last(tickets),
              selectedTicketItem.device_ticket_item_uuid,
              true
            )
          )
        )
      );
    }
  }

  @Action(VoidTicketItemSuccess)
  voidTicketItemSuccess(
    ctx: StateContext<TicketItemsStateModel>,
    { ticket }: VoidTicketItemSuccess
  ) {
    const normalizeTicketItems = NormalizrHelper.normalizeTicketItems(
      ticket.ticket_items
    ).entities.ticket_items;
    ctx.setState(
      produce((draft: TicketItemsStateModel) => {
        if (normalizeTicketItems) {
          draft.ticketItems.byId = _.merge(
            draft.ticketItems.byId,
            normalizeTicketItems
          );
        }
      })
    );
  }

  @Action(RemoveTicketItemSuccess)
  removeTicketItemSuccess(
    ctx: StateContext<TicketItemsStateModel>,
    payload: RemoveTicketItemSuccess
  ) {
    const { clearAllModifiers, ticketItemUuid, ticket } = payload;

    const removedTicketItem = ctx.getState().ticketItems.byId[ticketItemUuid];

    // const isSelectTicketItem = false;

    ctx.setState(
      produce((draft: TicketItemsStateModel) => {
        if (clearAllModifiers) {
          const selectedTicketItem =
            ctx.getState().ticketItems.byId[ticketItemUuid];

          if (selectedTicketItem) {
            draft.ticketItems.byId[ticketItemUuid].ticket_items = [];
          }

          draft.ticketItems = {
            byId: _.omit(
              draft.ticketItems.byId,
              selectedTicketItem.ticket_items
            ),
            allIds: draft.ticketItems.allIds.filter(
              (i) => !selectedTicketItem.ticket_items?.includes(i)
            )
          };
        } else {
          const parentUuid = removedTicketItem.parent_uuid;
          const removedTicketItems = removedTicketItem.ticket_items;

          if (parentUuid) {
            draft.ticketItems.byId[parentUuid].ticket_items = _.difference(
              draft.ticketItems.byId[parentUuid].ticket_items,
              [ticketItemUuid]
            );
          }

          draft.selectedTicketItem = null;

          if (removedTicketItems?.length) {
            draft.ticketItems = {
              allIds: _.difference(
                draft.ticketItems.allIds,
                removedTicketItem.ticket_items.concat(ticketItemUuid)
              ),
              byId: _.omit(
                draft.ticketItems.byId,
                removedTicketItem.ticket_items.concat(ticketItemUuid)
              )
            };
          } else {
            draft.ticketItems = {
              allIds: _.difference(draft.ticketItems.allIds, [ticketItemUuid]),
              byId: _.omit(draft.ticketItems.byId, [ticketItemUuid])
            };
          }
        }

        /** POSV3-1774: Discount do not update after remove item */
        if (ticket.ticket_items?.length) {
          const normalizeTicketItems = NormalizrHelper.normalizeTicketItems(
            ticket.ticket_items
          ).entities.ticket_items;

          _.forEach(
            normalizeTicketItems,
            (normalizedTicketItem, deviceTicketItemUuid) => {
              draft.ticketItems.byId[deviceTicketItemUuid] = {
                ...draft.ticketItems.byId[deviceTicketItemUuid],
                ...normalizedTicketItem
              };
            }
          );
        }
      })
    );

    ctx.dispatch(new ShowModifierGrid(false));
  }

  @Action(AddTicketItemDiscount)
  addTicketItemDiscount(
    ctx: StateContext<TicketItemsStateModel>,
    { discount_id }: AddTicketItemDiscount
  ) {
    const state = ctx.getState();
    const operator = this.store.selectSnapshot(APP_STATE_TOKEN).operator;
    return this.ticketService
      .addTicketItemDiscount(state.selectedTicketItem, operator.id, discount_id)
      .pipe(
        tap((ticket) => {
          this.modalController.dismiss();

          if (!ticket) {
            ctx.dispatch(
              new ExceptionError('Failed to apply discount to ticket item')
            );
            return;
          }

          const ticketItemSuccessList = ticket.ticket_items.map((item) => ({
            ...state.ticketItems.byId[item.device_ticket_item_uuid],
            ...item
          }));

          const actions = [
            /**
             * Since BE has returned all ticket items for updating the price of each ticket item that has been applied discount, we need to update the ticket items in the state.
             */
            new UpdateTicketItemSuccess(ticketItemSuccessList),
            new UpdateTicketSuccess(ticket)
          ];
          ctx.dispatch(actions);
        }),
        catchError((error) =>
          ctx.dispatch(new AddTicketItemDiscountError(error))
        )
      );
  }

  @Action(RemoveTicketItemDiscount)
  removeTicketItemDiscount(
    ctx: StateContext<TicketItemsStateModel>,
    { discount_id }: RemoveTicketItemDiscount
  ) {
    const state = ctx.getState();
    return this.ticketService
      .removeTicketItemDiscount(state.selectedTicketItem, discount_id)
      .pipe(
        tap((ticket) => {
          if (!ticket) {
            ctx.dispatch(
              new ExceptionError('Failed to remove discount from ticket item')
            );
            return;
          }

          const ticketItemSuccessList = ticket.ticket_items.map((item) => ({
            ...state.ticketItems.byId[item.device_ticket_item_uuid],
            ...item
          }));

          ctx.dispatch([
            /**
             * Since BE has returned all ticket items for updating the price of each ticket item that has been applied discount, we need to update the ticket items in the state.
             */
            new UpdateTicketItemSuccess(ticketItemSuccessList),
            new UpdateTicketSuccess({
              ...ticket,
              applied_discount_id: null,
              total_discounts: 0
            })
          ]);
        })
      );
  }

  @Action(UpdateTicketItemSuccess)
  updateTicketItemSuccess(
    ctx: StateContext<TicketItemsStateModel>,
    { data }: UpdateTicketItemSuccess
  ) {
    // If there is no data, return
    if (!data?.length) {
      return;
    }

    const ticketItems: Record<string, NormalizedTicketItem> =
      NormalizrHelper.normalizeTicketItems(<TicketItem[]>data).entities
        .ticket_items;

    ctx.setState(
      produce((draft: TicketItemsStateModel) => {
        Object.entries(ticketItems).forEach(([key, value]) => {
          draft.ticketItems.byId[key] = {
            ...draft.ticketItems.byId[key],
            ...value
          };
        });
      })
    );
  }

  private addTicketItemLocalAttributes(ticketItem: TicketItem): TicketItem {
    ticketItem.local_attributes = {
      removed: false
    };
    return ticketItem;
  }

  @Action(RemoveAllSskTicketItems)
  removeAllSskTicketItems(ctx: StateContext<TicketItemsStateModel>) {
    ctx.setState(
      produce((draft: TicketItemsStateModel) => {
        const feeTicketItem = _.find(
          draft.ticketItems.byId,
          (ti) => ti.is_fee_item
        );

        draft.ticketItems.allIds = feeTicketItem
          ? [feeTicketItem.device_ticket_item_uuid]
          : [];
        draft.ticketItems.byId = feeTicketItem
          ? { [feeTicketItem.device_ticket_item_uuid]: feeTicketItem }
          : {};
      })
    );
  }

  @Action(SelectNewSeat)
  unselectSelectedTicketItem(ctx: StateContext<TicketItemsStateModel>) {
    ctx.patchState({ selectedTicketItem: null });
  }

  @Action(RemoveTicketSuccess)
  removeAllTicketItemsOfDeletedTicket(
    ctx: StateContext<TicketItemsStateModel>,
    payload: RemoveTicketSuccess
  ) {
    const ticketState = this.store.selectSnapshot(TICKETS_STATE_TOKEN);
    const ticket =
      ticketState.tickets.byId &&
      ticketState.tickets.byId[payload.device_ticket_uuid];
    const ticketItemsState = ctx.getState();
    if (!ticket || !ticketItemsState.ticketItems.allIds) {
      return;
    }

    ctx.setState(
      produce((draft: TicketItemsStateModel) => {
        // _.remove(draft.ticketItems.allIds, )
        _.difference(draft.ticketItems.allIds, ticket.ticket_items);
        ticket.ticket_items.forEach((ti) => delete draft.ticketItems.byId[ti]);
      })
    );
  }

  @Action(FireTicketItemNotificationEvent)
  fireTicketItemNotificationEvent(
    ctx: StateContext<TicketItemsStateModel>,
    payload: FireTicketItemNotificationEvent
  ) {
    const firedTicketItemId = payload.data.device_ticket_item_uuid;

    if (!_.has(ctx.getState().ticketItems.byId, firedTicketItemId)) {
      return;
    }

    ctx.setState(
      produce((draft: TicketItemsStateModel) => {
        draft.ticketItems.byId[firedTicketItemId].status =
          TicketItemStatus.FIRED;
      })
    );
  }

  @Action(FireCourseNotificationEvent)
  fireCourseNotificationEvent(
    ctx: StateContext<TicketItemsStateModel>,
    payload: FireCourseNotificationEvent
  ) {
    const ticketItems = ctx.getState().ticketItems.byId;
    const firedTicketItems = _.toArray(ticketItems)
      .filter((ti) => ti.course === payload.data.course)
      .map((ti) => ti.device_ticket_item_uuid);

    ctx.setState(
      produce((draft: TicketItemsStateModel) => {
        firedTicketItems.forEach(
          (ti) => (draft.ticketItems.byId[ti].status = TicketItemStatus.FIRED)
        );
      })
    );
  }

  @Action(FireCourseSeatNotificationEvent)
  fireCourseSeatNotificationEvent(
    ctx: StateContext<TicketItemsStateModel>,
    payload: FireCourseSeatNotificationEvent
  ) {
    const firedTicketItems = this.store
      .selectSnapshot(TICKETS_STATE_TOKEN)
      .tickets.byId[payload.data.device_ticket_uuid].ticket_items.filter(
        (ti) =>
          ctx.getState().ticketItems.byId[ti].course === payload.data.course
      );

    ctx.setState(
      produce((draft: TicketItemsStateModel) => {
        firedTicketItems.forEach(
          (ti) => (draft.ticketItems.byId[ti].status = TicketItemStatus.FIRED)
        );
      })
    );
  }

  @Action(FireTicketNotificationEvent)
  fireTicketNotificationEvent(
    ctx: StateContext<TicketItemsStateModel>,
    payload: FireTicketNotificationEvent
  ) {
    const firedTicketItems =
      this.store.selectSnapshot(TICKETS_STATE_TOKEN).tickets.byId[
        payload.data.device_ticket_uuid
      ].ticket_items;

    ctx.setState(
      produce((draft: TicketItemsStateModel) => {
        firedTicketItems.forEach(
          (ti) => (draft.ticketItems.byId[ti].status = TicketItemStatus.FIRED)
        );
      })
    );
  }

  @Action(FireTableNotificationEvent)
  fireTableNotificationEvent(
    ctx: StateContext<TicketItemsStateModel>,
    payload: FireTableNotificationEvent
  ) {
    const ticketsById =
      this.store.selectSnapshot(TICKETS_STATE_TOKEN).tickets.byId;
    const seatsById = this.store.selectSnapshot(SEATS_STATE_TOKEN).seats.byId;
    const seats =
      this.store.selectSnapshot(TABLES_STATE_TOKEN).tables.byId[
        payload.data.table_id
      ].seats;
    const firedTicketItems = _.flatten(
      seats
        .filter((s) => !!seatsById[s].ticket)
        .map((s) => ticketsById[seatsById[s].ticket].ticket_items)
    );

    ctx.setState(
      produce((draft: TicketItemsStateModel) => {
        firedTicketItems.forEach(
          (ti) => (draft.ticketItems.byId[ti].status = TicketItemStatus.FIRED)
        );
      })
    );
  }

  @Action(RemoveSskTicketItems)
  removeSskTicketItems(
    ctx: StateContext<TicketItemsStateModel>,
    payload: RemoveSskTicketItems
  ) {
    return forkJoin(
      payload.ticketItems.map((device_ticket_item_uuid) =>
        this.removeTicketItemHandler(ctx, { device_ticket_item_uuid })
      )
    ).pipe(
      map((tickets) =>
        _.minBy(tickets, (ti) => +(ti?.outstanding_balance || 0))
      ),
      tap((ticket) => ctx.dispatch(new UpdateTicketSuccess(ticket)))
    );
  }

  @Action(FireTicketSuccess)
  setTicketItemsToFired(
    ctx: StateContext<TicketItemsStateModel>,
    { ticketItemUuidArray }: FireTicketSuccess
  ) {
    ctx.setState(
      produce((draft) => {
        ticketItemUuidArray.forEach((uuid) => {
          const draftTicketItem = draft.ticketItems.byId[uuid];
          draftTicketItem.status = TicketItemStatus.FIRED;
          draftTicketItem.ticket_items?.forEach((childUuid) => {
            draft.ticketItems.byId[childUuid].status = TicketItemStatus.FIRED;
          });
        });
      })
    );
  }

  private removeTicketItemHandler(
    ctx: StateContext<TicketItemsStateModel>,
    payload: VoidTicketItem
  ): Observable<Ticket> {
    const state = ctx.getState();
    if (!(state.selectedTicketItem || payload.device_ticket_item_uuid)) {
      return;
    }

    const deviceTicketItemUuid =
      payload.device_ticket_item_uuid || state.selectedTicketItem;

    ctx.setState(
      produce((draft: TicketItemsStateModel) => {
        const ticketItemById = draft.ticketItems.byId[deviceTicketItemUuid];
        if (ticketItemById && ticketItemById.local_attributes) {
          ticketItemById.local_attributes.removed = true;
        }
      })
    );

    const ticketItem = state.ticketItems.byId[deviceTicketItemUuid];
    if (ticketItem?.status === TicketItemStatus.FIRED) {
      return this.ticketService
        .voidTicketItem(deviceTicketItemUuid, payload)
        ?.pipe(
          tap((ticket) => {
            this.logRocketProvider.track(
              LOG_ROCKET_CUSTOM_EVENTS.TicketItem.Voided,
              {
                device_ticket_uuid: deviceTicketItemUuid,
                device_ticket_item_uuid: ticketItem.device_ticket_item_uuid
              }
            );
            ctx.dispatch(new VoidTicketItemSuccess(ticket));
          })
        );
    } else {
      return this.ticketService
        .cancelTicketItem(deviceTicketItemUuid)
        .pipe(
          tap((ticket) =>
            ctx.dispatch([
              new RemoveTicketItem([deviceTicketItemUuid]),
              new RemoveTicketItemSuccess(ticket, deviceTicketItemUuid)
            ])
          )
        );
    }
  }

  @Action(AddPickupDeliveryFeeToTicket)
  addPickupDeliveryFeeToTicket(
    ctx: StateContext<TicketItemsStateModel>,
    { pickupDeliveryFee }: AddPickupDeliveryFeeToTicket
  ) {
    return this.ticketService
      .addPickUpDeliveryFeeTicketItem(pickupDeliveryFee)
      .pipe(
        tap(({ device_ticket_item_uuid, ticket_items, ticket }) => {
          const ticketItem = ticket_items.find(
            (i) => i.device_ticket_item_uuid === device_ticket_item_uuid
          );
          const normalizedTicketItem = NormalizrHelper.normalizeTicketItems([
            ticketItem
          ]).entities.ticket_items[device_ticket_item_uuid];

          ctx.setState(
            produce((draft) => {
              draft.ticketItems = {
                allIds: draft.ticketItems.allIds.concat(
                  device_ticket_item_uuid
                ),
                byId: {
                  ...draft.ticketItems.byId,
                  [device_ticket_item_uuid]: normalizedTicketItem
                }
              };
            })
          );

          ctx.dispatch(
            new UpdateTicketSuccess({
              ...ticket,
              ticket_items
            })
          );
        })
      );
  }
}
