import { BaseData, MealTime, ScaleContainer } from './base.model';

// export type ValidateStaff = 'badge_scan' | 'badge_swipe' | 'rfid_tap' | 'facial_recognition' | 'none';
export type ValidateStaff =
  | 'badge'
  | 'badge_scan'
  | 'badge_swipe'
  | 'facial_recognition'
  | 'none';
export type ValidateResident = 'room_number' | ValidateStaff;

export enum LocationType {
  DiningRoom = 'dining_room',
  QuickService = 'quick_service',
  SelfServe = 'self_service',
  Services = 'services'
}

export enum CreditCardProcessingType {
  None = 'None',
  Trancloud = 'Trancloud',
  DCDirect = 'Dc_Direct',
  Stripe = 'Stripe'
}

export enum SelfServeSelectionMethod {
  Scan = 'barcode_scan_only',
  ScanAndSelectOnScreen = 'barcode_and_select_on_screen',
  SelectOnScreen = 'on_screen_select_only'
}

export interface LocationList extends BaseData {
  type: LocationType;
}

export interface AutoAddProductSelectedMealPlan {
  breakfast: number[];
  lunch: number[];
  dinner: number[];
}

export type PickupDeliveryFeeType = 'delivery' | 'pick_up';

type StringOrNumber = string | number;

export interface PickupDeliveryFee {
  id: number;
  type: PickupDeliveryFeeType;
  product_id: number;
  amount: StringOrNumber;
  product_name: string;
}

export interface PosLocation extends LocationList {
  meal_delivery_area_id: number;
  accepted_payment_methods: AcceptedPaymentMethod;
  credit_card_processing: CreditCardProcessingType;
  merchant_account_id: string;
  app_timeout: number;
  meal_end_times: MealEndTimes;
  auto_choose_mealplan_when_available: boolean;
  auto_add_products_when_meal_plan_selected: AutoAddProductSelectedMealPlan;
  auto_close_on_full_payment: boolean;
  debit_meal_plan_on_zero_balance_tickets: boolean;
  can_switch_to_self_serve: boolean;
  external_display_image: string;
  dining_room_settings: DiningRoomSettings;
  quick_service_settings: QuickServiceSettings;
  self_serve_settings: SelfServeSettings;
  reservation_settings: ReservationSettings;
  payment_locations: PaymentLocation[];
  tare_weight_containers: ScaleContainer[];
  print_receipt_for_cash_payment: boolean;
  print_receipt_for_non_cash: boolean;
  enable_scheduled_orders: boolean;
  // meal_delivery_area_timings: MealDeliveryAreaTiming[];
  enable_takeout_tab: boolean;
  reservation_meal_configurations: ReservationMealConfiguration[];
  pos_price_book_id: number;
  pickup_delivery_fees: PickupDeliveryFee[];
}

export interface ReservationMealConfiguration {
  day: number | null;
  default_reservation: number | null;
  end_time: string | null;
  id: number;
  max_capacity_total: number | null;
  max_seatings_per_15_minutes: number | null;
  meal_id: MealTime;
  start_time: string | null;
  time_to_block_table_before_reservation: number | null;
  total_number_of_seats: number;
}

export interface MealDeliveryAreaTiming {
  id: number | undefined; // POSV3-2313: ASAP means undefined
  day: number;
  from_am: boolean;
  from_hr: number;
  from_min: number;
  meal_id: number;
  to_am: boolean;
  to_hr: number;
  to_min: number;
  exception: boolean;
  meal_delivery_area_id: number;
  max_orders: number;
  total_orders: number;
  max_served: boolean;
}

export interface AcceptedPaymentMethod {
  cash: boolean;
  credit_card: boolean;
  manual_credit_card: boolean;
  gift_card: boolean;
  bill_to_room: boolean;
  diner_charge_account: boolean;
  department_charge_account: boolean;
  guests_charge_residents_meal_plan: boolean;
}

export interface MealEndTimes {
  breakfast: string;
  lunch: string;
  dinner: string;
}

export interface ReservationSettings {
  auto_block_table_after?: number; //* minutes
  enable_reservations: boolean;
}

export interface DiningRoomSettings {
  hide_fire_table: boolean;
  coursing_enabled: boolean;
  personalized_resident_menus: boolean;
}

export interface QuickServiceSettings {
  gratuity_accepted: boolean;
  round_up_for_charity: boolean;
  charity_product_id: number;
}

interface NewSskSettings {
  resident_enable_dinein: boolean;
  resident_dinein_request_special_instructions: boolean;
  // (current name: request_delivery_instructions, type: boolean)
  resident_dinein_special_instruction_prompts: string;
  //  (current name: delivery_instruction_prompts, type: string)
  resident_enable_takeout: boolean;
  resident_takeout_request_special_instructions: boolean;
  resident_takeout_special_instruction_prompts: string;
  resident_enable_delivery: boolean;
  resident_delivery_request_special_instructions: boolean;
  resident_delivery_special_instruction_prompts: string;

  guest_enable_dinein: boolean;
  guest_dinein_request_special_instructions: boolean;
  guest_dinein_special_instruction_prompts: string;
  guest_enable_takeout: boolean;
  guest_takeout_request_special_instructions: boolean;
  guest_takeout_special_instruction_prompts: string;
  guest_enable_delivery: boolean;
  guest_delivery_request_special_instructions: boolean;
  guest_delivery_special_instruction_prompts: string;

  staff_enable_dinein: boolean;
  staff_dinein_request_special_instructions: boolean;
  staff_dinein_special_instruction_prompts: string;
  staff_enable_takeout: boolean;
  staff_takeout_request_special_instructions: boolean;
  staff_takeout_special_instruction_prompts: string;
  staff_enable_delivery: boolean;
  staff_delivery_request_special_instructions: boolean;
  staff_delivery_special_instruction_prompts: string;

  // enable only for delivery
  add_delivery_fee: boolean;
  delivery_fee_amount: number;
  delivery_product_id: number;
  delivery_product_name: string;
}

export type SelfServeSettings = NewSskSettings & {
  permit_resident: boolean;
  permit_guest: boolean;
  permit_staff: boolean;
  validate_staff: ValidateStaff;
  validate_resident: ValidateResident;
  default_operator_id: number;
  selection_method: SelfServeSelectionMethod;
  fire_order_on_payment: boolean;
  print_receipt_on_payment: boolean;
  request_delivery_instructions: boolean;
  delivery_instruction_prompt: string;
};

export interface PaymentLocation extends BaseData {
  default_printer_id?: number;
}

export type LiveLocationType = 'LiveLocation::Building' | 'LiveLocation::Wing';

export interface LiveLocationWing extends BaseData {
  parent_id: number;
  sort_order: number;
  type: 'LiveLocation::Wing';
}

export interface LiveLocationBuilding extends BaseData {
  type: 'LiveLocation::Building';
  parent_id: number | null;
  sort_order: number;
  wings: LiveLocationWing[];
}

interface LastMeal {
  name: string;
  date: string;
}

export interface CensusReport {
  building: string;
  first_name: string;
  last_meal: LastMeal;
  last_name: string;
  photo_url: string;
  wing: string;
  room: string;
  /** Additional FE attributes */
  residentName?: string;
  dateMeal?: string;
  dateNumber?: number;
}

export type OffPremiseReport = Omit<CensusReport, 'last_meal'> & {
  return_date: string;
  meal: string;
};
