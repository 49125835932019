import {
  DinerType,
  ErrorCodeKey,
  MacroGridItem,
  MenuCategory,
  MenuItem,
  SelfServiceTicketItem
} from 'src/app/models';
import { SskOrderType } from 'src/app/store/self-service/self-service.selector';

export class Initialize {
  static readonly type = '[Self Service] Initialize';
}

export class CompleteSelfServiceSetup {
  static readonly type = '[Self Service] Complete Self Service Setup';
}

export class SskGetDinerByBarcode {
  static readonly type = '[Self Service] Get Diner By Barcode';
  constructor(public barcode: string, public dinerType: DinerType) {}
}

export class SskGetDinerByBarcodeFailed {
  static readonly type = '[Self Service] Get Diner By Barcode Failed';
  constructor(public readonly errorCode: ErrorCodeKey) {}
}

export class GetDinerByBarcodeError {
  static readonly type = '[Self Service] Get Diner By Barcode Error';
  constructor(public errorMessage: string) {}
}

export class StartOver {
  static readonly type = '[Self Service] Start Over';
  constructor(
    public readonly isCancelTicket = false,
    public readonly redirectToSetup = true
  ) {}
}

export class CancelOrder {
  static readonly type = '[Self Service] Cancel Order';
}

export class ResetSelfServiceState {
  static readonly type = '[Self Service] Reset Self Service State';
}

export class MenuChangeEventFromWs {
  static readonly type = '[Self Service] Menu Change Event From Websocket';
}

export class SelectCategory {
  static readonly type = '[Self Ordering Page] Select Category';
  constructor(public readonly macroGridItem: MacroGridItem | null = null) {}
}

export class ViewAllItems {
  static readonly type = '[Self Ordering Page] View All Items';
}

export class ChangeQuantityItem {
  static readonly type = '[Self Ordering Card] Change Quantity Item';
  constructor(
    public readonly ticketItem: SelfServiceTicketItem,
    public readonly isIncrease: boolean
  ) {}
}

export class RemoveSskTicketItems {
  static readonly type = '[Self Ordering Card] Remove Ssk Ticket Items';
  constructor(public readonly ticketItems: string[]) {}
}

export class SskAddTicketItem {
  static readonly type = '[Self Ordering Items] Add Ticket Item';
  constructor(public readonly item: MenuItem) {}
}

export class FocusOnCategoryInMenuItemList {
  static readonly type =
    '[Self Ordering Items] Focus On Category In Menu Item List';
  constructor(public readonly category: MenuCategory) {}
}

export class FocusOnCategoryInCategoryList {
  static readonly type =
    '[Self Ordering Items] Focus On Category In Category List';
  constructor(public readonly category: MenuCategory) {}
}

export class FocusOnMenuItem {
  static readonly type = '[Self Ordering Items] Focus On Menu Item';
  constructor(public readonly menuItem: MenuItem) {}
}

export class ResetFocusState {
  static readonly type = '[Self Ordering Items] Reset Focus State';
}

export class SelectOrderType {
  static readonly type = '[Self Ordering Page] Select Order Type';
  constructor(
    public readonly orderType: SskOrderType,
    public readonly delivery_note?: string
  ) {}
}

export class SetDeliveryNote {
  static readonly type = '[Self Ordering Page] Set Delivery Note';
  constructor(public readonly deliveryNote: string) {}
}
