import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable, forkJoin, map } from 'rxjs';
import _ from 'lodash';

import { MacroGrid, Menu } from 'src/app/models';

import { BaseService } from '../base.service';
import { ErrorService } from 'src/app/services/error/error.service';
import { ApiType } from 'src/app/pos.config';
import { PosQuantityRestriction } from 'src/app/models/pos-manager.model';
import { APP_STATE_TOKEN } from 'src/app/store/app/app.state.model';
import { AppStateHelper } from 'src/app/store/app/app.state.helper';

@Injectable({
  providedIn: 'root'
})
export class MenuService extends BaseService {
  constructor(
    store: Store,
    private http: HttpClient,
    private readonly errorService: ErrorService
  ) {
    super(store);
  }

  getMacroGrids(): Observable<MacroGrid[]> {
    return this.http
      .get<{ macro_grids: MacroGrid[] }>(this.getLocationUrl('macro_grids'))
      .pipe(
        this.errorService.retryPipe(ApiType.Query),
        map((response) => response.macro_grids || [])
      );
  }

  getMacroGridDetail(
    meal_id: number,
    macro_grid_id: number
  ): Observable<MacroGrid> {
    return this.http
      .get<MacroGrid>(
        this.getLocationUrl(`macro_grids/${macro_grid_id}`),
        meal_id ? { params: { meal_id } } : undefined
      )
      .pipe(this.errorService.retryPipe(ApiType.Query));
  }

  getMenuAndQuantityRestrictions(params: {
    meal_id?: number;
    date?: string;
  }): Observable<[Menu, PosQuantityRestriction[]]> {
    return forkJoin([
      this.getMenu(params),
      this.getQuantityRestrictions(params.date)
    ]);
  }

  private getMenu(params: {
    meal_id?: number;
    date?: string;
  }): Observable<Menu> {
    params = _.omitBy(params, _.isNil);
    return this.http
      .get<Menu>(this.getLocationUrl('menu'), { params })
      .pipe(this.errorService.retryPipe());
  }

  private getQuantityRestrictions(
    date: string
  ): Observable<PosQuantityRestriction[]> {
    const apiBaseUrl = this.store.selectSnapshot(APP_STATE_TOKEN).apiBaseUrl;
    const apiUrl = AppStateHelper.getApiUrl(apiBaseUrl);
    const api = 'quantity_restrictions';
    return this.http
      .get<PosQuantityRestriction[]>(apiUrl + api, {
        params: { date }
      })
      .pipe(
        this.errorService.retryPipe(),
        map((response) => response || [])
      );
  }
}
