import { HttpErrorResponse } from '@angular/common/http';
//-------------------------------------
import {
  AddTicketItemResponse,
  PickupDeliveryFee,
  Ticket,
  TicketItem,
  TicketItemRequest
} from 'src/app/models';

export class SelectTicketItem {
  static readonly type = '[Ticket Items] Select a Ticket Item';
  constructor(public readonly ticketItemId: string = null) {}
}

export class AddTicketItem {
  static readonly type = '[Ticket Items] Add a Ticket Item';
  constructor(
    public readonly ticketItem: Partial<TicketItemRequest>,
    public readonly canBeCancelled: boolean = false
  ) {}
}

export class AddTicketItemDone {
  static readonly type = '[Ticket Items] Add Ticket Item Done';
  constructor(
    public readonly ticketItemPayload: Partial<TicketItemRequest>,
    public readonly addTicketResponse: AddTicketItemResponse,
    public readonly canBeCancelled: boolean = false
  ) {}
}

export class AddTicketItemWithPrompts {
  static readonly type = '[Ticket Items] Add a Ticket Item with prompts';
  constructor(
    public readonly ticketItem: TicketItemRequest,
    public readonly canBeCancelled: boolean
  ) {}
}

export class ResetWaitingTicketItem {
  static readonly type = '[Ticket Items] Reset Waiting Ticket Items';
  constructor(public isRemove: boolean = false) {}
}

export class AddChildItem {
  static readonly type = '[Ticket Items] Add Child Item';
  constructor(
    public readonly ticketItemsUuids: string[],
    public readonly parentTicketItemUuid?: string
  ) {}
}

export class UpdateTicketItem {
  static readonly type = '[Ticket Items] Update a Ticket Item';
  constructor(public readonly data: Partial<TicketItem>) {}
}

export class VoidTicketItem {
  static readonly type = '[Ticket Items] Void a Ticket Item';
  constructor(
    public operator_id?: number,
    public approving_operator_id?: number,
    public void_reason?: string,
    public device_ticket_item_uuid?: string
  ) {}
}

export class RemoveAllModifiersBelongTicketItem {
  static readonly type =
    '[Ticket Items] Remove All Modifiers Belong Ticket Item';
}

export class AddTicketItemDiscount {
  static readonly type = '[Ticket Items] Add Ticket Item Discount';
  constructor(public discount_id: number) {}
}

export class AddTicketItemDiscountError {
  static readonly type = '[Ticket Items] Add Ticket Item Discount Error';
  constructor(public error: HttpErrorResponse) {}
}

export class RemoveTicketItemDiscount {
  static readonly type = '[Ticket Items] Remove Ticket Item Discount';
  constructor(public discount_id: number) {}
}

export class UpdateTicketItemSuccess {
  static readonly type = '[Ticket] Update Ticket Item Success';
  constructor(public readonly data: Partial<TicketItem>[]) {}
}

export class VoidTicketItemSuccess {
  static readonly type = '[Ticket Items] Void a Ticket Item Success';
  constructor(public ticket: Ticket) {}
}

export class AddPickupDeliveryFeeToTicket {
  static readonly type = '[Ticket Item] Add Pickup Delivery Fee To Ticket';
  constructor(public readonly pickupDeliveryFee: PickupDeliveryFee) {}
}
