import { Injectable } from '@angular/core';
import { Actions, ofActionCompleted, Store } from '@ngxs/store';
import { filter, take } from 'rxjs';
import _ from 'lodash';
//------------------------------------------------------------------------->
import { CommonConstant, TicketConstant } from 'src/app/constants';
import { GeneralHelper } from 'src/app/helpers';
import {
  BaseMenuItem,
  LocationType,
  MenuItemType,
  NormalizedTicket,
  PriceType,
  ProductType,
  TakeoutOrderStatus,
  Ticket,
  TicketItem,
  TicketItemRequest,
  TicketItemType
} from 'src/app/models';
import {
  ModifierPromptOption,
  ModifierPromptOptionType
} from 'src/app/models/pos-manager.model';
import { LOCATION_STATE_TOKEN } from 'src/app/store/location/location.state';
import { LocationStateHelper } from 'src/app/store/location/location.state.helper';
import { MENU_STATE_TOKEN } from 'src/app/store/menu/menu.state.model';
import { TAKEOUT_DELIVERY_STATE_TOKEN } from 'src/app/store/takeout-delivery/takeout-delivery.state';
import {
  AddTicketItem,
  AddTicketItemWithPrompts
} from 'src/app/store/ticket-items/ticket-items.action';
import { CreateTicket } from 'src/app/store/tickets/tickets.action';
import { TICKETS_STATE_TOKEN } from 'src/app/store/tickets/tickets.state';

@Injectable({
  providedIn: 'root'
})
export class TicketProvider {
  constructor(
    private readonly _actions$: Actions,
    private readonly store: Store
  ) {}

  ticketItemAddingHandler(
    menuItem: BaseMenuItem,
    device_ticket_item_uuid?: string
  ): void {
    if (!this.selectedTicket?.status) {
      // If ticket is being created, wait for ticket creation to complete
      this._actions$
        .pipe(
          ofActionCompleted(CreateTicket),
          take(1),
          filter(({ result }) => !result.error)
        )
        .subscribe(() =>
          this.ticketItemAddHandler(menuItem, device_ticket_item_uuid)
        );

      if (!this._isTicketCreating) {
        this.store.dispatch(new CreateTicket());
      }
    } else {
      this.ticketItemAddHandler(menuItem, device_ticket_item_uuid);
    }
  }

  ticketItemAddingWithPrompts(
    menuItem: BaseMenuItem,
    options: ModifierPromptOption[]
  ) {
    if (!this.selectedTicket?.status) {
      this.store.dispatch(new CreateTicket()).subscribe(() => {
        this.ticketItemAddWithPromptsHandler(menuItem, options);
      });
    } else {
      this.ticketItemAddWithPromptsHandler(menuItem, options);
    }
  }

  canAddTicketItem(
    ticket: Ticket | NormalizedTicket,
    isTakeOutTicket: boolean
  ) {
    return (
      ticket &&
      (!ticket.status ||
        TicketConstant.ABLE_ADD_NEW_ITEM_STATUS.includes(ticket.status) ||
        // POSV3-1303 Allow add ticket/ modifier for scheduled/unpaid
        (isTakeOutTicket &&
          ticket.takeout_order_status === TakeoutOrderStatus.SCHEDULED &&
          !ticket.takeout_order_payment_status))
    );
  }

  ticketItemDuplicateHandler(ticketItem: TicketItem) {
    const item = this.duplicateTicketItem(ticketItem);
    this.store.dispatch(new AddTicketItem(item));
  }

  private ticketItemAddWithPromptsHandler(
    menuItem: BaseMenuItem,
    options: ModifierPromptOption[]
  ) {
    menuItem.quantity = menuItem.quantity || 1;
    const item = this.initNewTicketItem(menuItem, null);
    item.children_attributes = options.map((option) =>
      this.initNewTicketItemForModifierPrompt(item, option)
    );

    this.store.dispatch(
      new AddTicketItemWithPrompts(item, this.selectedTicket?.can_be_cancelled)
    );
    menuItem.quantity = 1;
  }

  private ticketItemAddHandler(
    menuItem: BaseMenuItem,
    device_ticket_item_uuid?: string
  ) {
    menuItem.quantity = menuItem.quantity || 1;
    const item = this.initNewTicketItem(menuItem, device_ticket_item_uuid);
    this.store.dispatch(
      new AddTicketItem(item, this.selectedTicket?.can_be_cancelled)
    );
    menuItem.quantity = 1;
  }

  private initNewTicketItemForModifierPrompt(
    parentRequest: TicketItemRequest,
    {
      added_price,
      text_label,
      pos_product,
      category,
      fullMenuItem,
      option_type
    }: ModifierPromptOption
  ): TicketItemRequest {
    const item: TicketItemRequest = {
      course: parentRequest.course,
      base_price: +added_price || 0,
      name: text_label || pos_product?.name || category?.name,
      quantity: 1,
      amount: added_price ? GeneralHelper.asDecimal(added_price) : 0,
      category_id: category?.id || fullMenuItem?.category_id,
      kitchen_quantity:
        fullMenuItem?.amount && fullMenuItem?.unit
          ? `${fullMenuItem.amount} ${fullMenuItem.unit}`
          : '1 Each',
      type: TicketItemType.MODIFIER
    };

    if (
      option_type === ModifierPromptOptionType.Category
      // ModifierPromptOptionType.CategoryChildItem
    ) {
      // this logic base on the add normal menu item (method initNewTicketItem)
      switch (fullMenuItem?.type) {
        case MenuItemType.Product:
          item.pos_product_id = fullMenuItem?.id;
          item.kitchen_quantity = fullMenuItem.unit;
          break;
        case MenuItemType.Food:
          item.food_id = fullMenuItem?.food_id;
          break;
        case MenuItemType.Plate:
          item.plate_id = fullMenuItem?.id;
          break;
      }
    } else if (option_type === ModifierPromptOptionType.PosProduct) {
      item.pos_product_id = pos_product?.id;
      item.kitchen_quantity = fullMenuItem?.unit || item.kitchen_quantity;
    }

    return item;
  }

  private initNewTicketItem(
    menuItem: BaseMenuItem,
    device_ticket_item_uuid?: string
  ): TicketItemRequest {
    const location = this.store.selectSnapshot(LOCATION_STATE_TOKEN).location;
    const locationType = location.type;
    const isCoursingEnabled: boolean =
      LocationStateHelper.getIsCoursingEnabled(location);
    const isDiningRoom: boolean = locationType === LocationType.DiningRoom;
    const isTakeoutDelivery = this.store.selectSnapshot(
      TAKEOUT_DELIVERY_STATE_TOKEN
    ).enableTakeOutDelivery;

    // Determine course
    let course = null; // Replace 0 with null when DEF-3841 is fixed on backend
    // Set item course if coursing enabled for dining room locations only and not on takeout/delivery
    if (!isTakeoutDelivery && isDiningRoom && isCoursingEnabled) {
      const courseByCategory = this.store
        .selectSnapshot(MENU_STATE_TOKEN)
        .menu.categories.find((cat) => cat.id === menuItem.category_id)?.course;
      course =
        courseByCategory ||
        (CommonConstant.ALL_COURSES.includes(menuItem.course)
          ? menuItem.course
          : course);
    }

    // Setup ticket item details
    const item: TicketItemRequest = {
      course,
      base_price: menuItem.price || 0,
      name: menuItem.name,
      quantity: menuItem.quantity,
      amount: GeneralHelper.asDecimal(menuItem.price),
      category_id: menuItem.category_id,
      kitchen_quantity:
        menuItem.amount && menuItem.unit
          ? `${menuItem.amount} ${menuItem.unit}`
          : '1 Each'
    };

    if (item.base_price && item.base_price.toString().endsWith('.')) {
      item.base_price = Math.round(item.base_price);
    }

    if (menuItem.type === MenuItemType.Product) {
      item.pos_product_id = menuItem.id;
      item.type = TicketItemType.PRODUCT;
      item.kitchen_quantity = menuItem.unit;
      if (menuItem.product_type === ProductType.GIFT_CARD) {
        item.type = TicketItemType.GIFT_CARD;
        item.gift_card_code = menuItem.gift_card_code;
      }
    } else if (menuItem.type === MenuItemType.Food) {
      item.food_id = menuItem.food_id;
      item.type = TicketItemType.FOOD;
    } else if (menuItem.type === MenuItemType.Plate) {
      item.plate_id = menuItem.id;
      item.type = TicketItemType.PLATE;
    } else {
      item.type = TicketItemType.MODIFIER;
      item.parent_uuid = device_ticket_item_uuid;
    }

    if (device_ticket_item_uuid) {
      // this item is added as modifier item
      item.parent_uuid = device_ticket_item_uuid;
    }

    if (
      menuItem.price_type &&
      menuItem.price_type !== PriceType.FIXED &&
      menuItem.price_type !== PriceType.VARIABLE
    ) {
      const scaleData: any = _.cloneDeep(menuItem.scale);
      item.base_price = scaleData.item_price_by_weight;
      scaleData.weight = scaleData.weight_without_container;
      scaleData.item_price_by_weight = true;
      if (_.has(scaleData, 'weight_without_container')) {
        delete scaleData.weight_without_container;
      }
      item.scale_data = JSON.stringify(scaleData).replace(/"/g, '\\"');
    }

    return item;
  }

  private duplicateTicketItem(
    ticketItem: TicketItem,
    uuid?: string
  ): TicketItemRequest {
    const device_ticket_item_uuid = GeneralHelper.generateUuid();

    const item: TicketItemRequest = {
      device_ticket_item_uuid,
      course: ticketItem.course,
      base_price: ticketItem.base_price,
      name: ticketItem.name,
      quantity: 1,
      amount: GeneralHelper.asDecimal(ticketItem.base_price),
      category_id: ticketItem.category_id,
      type: ticketItem.type,
      pos_product_id: ticketItem.pos_product_id,
      kitchen_quantity: ticketItem.kitchen_quantity,
      food_id: ticketItem.food_id,
      plate_id: ticketItem.plate_id,
      scale_data: ticketItem.scale_data
    };

    if (ticketItem.scale_data) {
      item.scale_data = ticketItem.scale_data;
    }

    if (ticketItem.gift_card_data) {
      item.gift_card_code = ticketItem.gift_card_data.number;
    }

    if (uuid) {
      item.parent_uuid = uuid;
    }

    if (ticketItem.ticket_items) {
      item.children_attributes = ticketItem.ticket_items.map((i) =>
        this.duplicateTicketItem(i, device_ticket_item_uuid)
      );
    }

    return item;
  }

  private get selectedTicket(): NormalizedTicket {
    const ticketsState = this.store.selectSnapshot(TICKETS_STATE_TOKEN);
    const ticketUuid = ticketsState.selectedTicket.data;
    const ticketsById = ticketsState.tickets.byId;
    return !!ticketUuid && !!ticketsById ? ticketsById[ticketUuid] : null;
  }

  private get _isTicketCreating(): boolean {
    return this.store.selectSnapshot(TICKETS_STATE_TOKEN).isTicketCreating;
  }
}
