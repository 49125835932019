import { StateToken } from '@ngxs/store';
import {
  MacroGrid,
  MacroGridItem,
  MacroGridList,
  MacroGridView,
  MealTime,
  Menu,
  MenuItem,
  StateObject
} from 'src/app/models';
import { PosQuantityRestriction } from 'src/app/models/pos-manager.model';

export type ResidentMenuTally = Omit<
  Menu,
  'products' | 'categories' | 'plates'
>;

export type PosStoredMenuTally = {
  [dinerId: string]: {
    [date_meal_id: string]: ResidentMenuTally;
  };
};

export interface MenuStateModel {
  menu: Menu;
  menu_by_meal: Record<string, Menu>;
  // key is barcode, value is array of products with has same barcode, normally, this array is just one record, if it has more than one, then it should be error
  barcodeProducts: Record<string, MenuItem[]>;
  // key is meal, value is a dictionary <barcode, products[]>
  barcodeProductsByMeal: _.Dictionary<_.Dictionary<MenuItem[]>>;
  menuItemsWithModifierPrompts: MenuItem[];
  menuItemsWithModifierPromptsByMeal: Record<string, MenuItem[]>;

  macroGrids: StateObject<MacroGridList[]>;
  // macro_grids_list: MacroGridList[];
  macro_grid_view: MacroGridView;
  default_macro_grid: MacroGrid;
  default_macro_grid_by_meal: _.Dictionary<MacroGrid>;
  default_modifier_macro_grid: MacroGrid;
  default_modifier_macro_grid_by_meal: _.Dictionary<MacroGrid>;
  selected_macro_grid: MacroGrid | MacroGridItem;
  selected_modifier_macro_grid: MacroGrid | MacroGridItem;
  macro_stack: (MacroGridItem | MacroGrid)[];
  modifier_macro_stack: MacroGridItem[];
  isModifierGridVisible: boolean;
  menu_tally: PosStoredMenuTally;
  menu_date: string;
  menu_selected_date_meal_id: string;
  selected_meal_id: number;
  quantity_restrictions: Record<string, PosQuantityRestriction[]>;
}

export const MENU_STATE_TOKEN = new StateToken<MenuStateModel>('menu');
