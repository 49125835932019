import {
  MacroGrid,
  MacroGridItem,
  MacroGridList,
  MacroGridView,
  QuantityRestrictionChangedMessage,
  QuantityRestrictionDeletedMessage,
  MealTime,
  MenuTallyFoodDetail,
  Menu,
  RequestMealId
} from 'src/app/models';
import { PosQuantityRestriction } from 'src/app/models/pos-manager.model';

export class GetMenuData {
  static readonly type = '[Menu] Get Menu Data';
  constructor(public isLoadSpecificMenu: boolean = false) {}
}

export class GetMenu {
  static readonly type = '[Menu] Get Menu';
  constructor(public readonly mealId?: number, public readonly date?: string) {}
}

export class GetMenuSuccess {
  static readonly type = '[Menu] Get Menu Success';

  constructor(
    public readonly requestMealId: RequestMealId,
    public readonly menu: Menu,
    public readonly quantity_restrictions: PosQuantityRestriction[]
  ) {}
}

export class GetMacroGrids {
  static readonly type = '[Menu] Get Macro Grids';
}

export class GetMacroGridsSuccess {
  static readonly type = '[Menu] Get Macro Grids Success';
  constructor(public readonly macro_grids_list: MacroGridList[]) {}
}

export class GetMacroGridDetail {
  static readonly type = '[Menu] Get Macro Grid Detail';
  constructor(public meal_id?: number, public runSecondTime = false) {}
}

export class GetMacroGridDetailSuccess {
  static readonly type = '[Menu] Get Macro Grid Detail Success';
  constructor(
    public readonly mealId: MealTime,
    public readonly macroGrid: MacroGrid,
    public readonly modifierMacroGrid: MacroGrid
  ) {}
}

export class UpdateMacroGridView {
  static readonly type = '[Menu] Update Macro Grid View';
  constructor(public macro_grid_view: MacroGridView) {}
}

export class UpdateSelectedMacroGrid {
  static readonly type = '[Menu] Update Selected Macro Grid';
  constructor(public macro_grid_item: MacroGrid | MacroGridItem) {}
}

export class UpdateSelectedModifierMacroGrid {
  static readonly type = '[Menu] Update Selected Modifier Macro Grid';
  constructor(public macro_grid_item: MacroGrid | MacroGridItem) {}
}

export class BackToSelectedMacroGrid {
  static readonly type = '[Menu] Back To Selected Macro Grid';
}

export class ResetMacroGrid {
  static readonly type = '[Menu] Reset Macro Grid';
}

export class ShowModifierGrid {
  static readonly type = '[Menu] Show Modifier Grid';
  constructor(public isModifierGridVisible = true) {}
}

export class GetMenuTally {
  static readonly type = '[Menu] Get Menu Tally';
  constructor(
    public readonly diner_id: number,
    public readonly meal_id: number,
    public readonly date: string
  ) {}
}

export class GetMenuTallySuccess {
  static readonly type = '[Menu] Get Menu Tally Success';
  constructor(
    public readonly food_details: MenuTallyFoodDetail[],
    public readonly diner_id: number,
    public readonly date_meal_id: string
  ) {}
}

export class ResetGridView {
  static readonly type = '[Menu] Reset Grid View';
}

export class SetSelectedMealId {
  static readonly type = '[Menu] Set Selected Meal Id';
  constructor(public readonly mealId: number) {}
}

export class DeleteQuantityRestriction {
  static readonly type = '[Menu] Delete Quantity Restriction';
  constructor(public readonly restriction: QuantityRestrictionDeletedMessage) {}
}

export class UpdateQuantityRestriction {
  static readonly type = '[Menu] Update Quantity Restriction';
  constructor(public readonly restriction: QuantityRestrictionChangedMessage) {}
}
