import * as Sentry from '@sentry/angular';

import { ErrorHandler } from '@angular/core';
import { environment } from 'src/environments/environment';

class SentryErrorHandler implements ErrorHandler {
  constructor() {
    Sentry.init({
      dsn: 'https://9c68fe480d82436eb666ef5188370df2@sentry.io/2526423',
      release: '3.19.36-dev.0',
      environment: environment.MSPOS_ENVIRONMENT,
      ignoreErrors: ['Non-Error exception captured']
    });
  }

  handleError(error: any) {
    if (environment.MSPOS_IS_DEPLOYED_BUILD) {
      const exception =
        error.error || error.message || error.originalError || error;
      Sentry.captureException(exception);
    }

    //Capture errors in the console so DataDog Browser Logs can pick it up
    //Ignore cordova_not_available errors
    if (error !== 'cordova_not_available') {
      console.error(error);
    }
  }
}

export function getErrorHandler(): ErrorHandler {
  if (environment.MSPOS_IS_DEPLOYED_BUILD) {
    return new SentryErrorHandler();
  }
  return new ErrorHandler();
}
