import { schema } from 'normalizr';
import {
  Diner,
  dinerSchema,
  PaymentRequestType,
  Scale,
  SelfServiceTicketItem,
  TransactionRefund
} from '.';
import { BaseDiscount, MealPlan } from './base.model';

export enum TicketType {
  QuickTicket = 'quick_ticket',
  SelfTicket = 'self_ticket',
  SeatedTicket = 'seated_ticket',
  DeliveryTicket = 'delivery_ticket'
}

export enum TicketStatus {
  EMPTY = 'empty',
  IN_PROGRESS = 'in_progress',
  ON_HOLD = 'on_hold',
  PARTIAL_PAID = 'partial_paid',
  PAID = 'paid',
  FIRED = 'fired',
  CLOSED = 'closed',
  MERGED = 'merged',
  PARTIAL_REFUND = 'partial_refund',
  FULL_REFUND = 'fully_refunded',
  CANCELLED = 'cancelled',
  VOIDED = 'voided',
  IS_REFUND = 'is_refund',
  TAKEOUT_ORDER_IN_PROGRESS = 'takeout_order_in_progress'
}

export enum TicketItemStatus {
  FIRED = 'fired',
  HOLD = 'hold',
  LOCAL = 'local',
  VOIDED = 'voided',
  WAITING = 'waiting',
  REFUNDED = 'refunded',
  IS_REFUND = 'is_refund'
}

export enum DeliveryType {
  TO_GO = 'to_go',
  TO_TABLE = 'to_table',
  TO_ROOM = 'to_room',
  TO_SEAT = 'to_seat',
  DELIVERY = 'delivery',
  PICK_UP = 'pick_up'
}

export enum PosTicketItem {
  Refund = 'Pos::Tickets::Refund'
}

export enum TicketSource {
  POS = 'pos',
  POS_TAKEOUT = 'pos_takeout',
  PORTAL = 'portal'
}

export enum TicketItemType {
  PRODUCT = 'Pos::TicketItems::Product',
  FOOD = 'Pos::TicketItems::Food',
  MODIFIER = 'Pos::TicketItems::Modifier',
  PLATE = 'Pos::TicketItems::Plate',
  GIFT_CARD = 'Pos::TicketItems::GiftCard'
}

export enum TakeoutOrderPaymentStatus {
  PRE_PAID = 'pre_paid',
  PAY_ON_PICKUP = 'pay_on_pickup',
  PAY_ON_DELIVERY = 'pay_on_delivery'
}

export enum TakeoutOrderStatus {
  ORDER_ENTRY = 'order_entry',
  SCHEDULED = 'scheduled',
  IN_PRODUCTION = 'in_production',
  PRODUCTION_COMPLETE = 'production_complete',
  OUT_FOR_DELIVERY = 'out_for_delivery',
  COMPLETED = 'completed'
}

export interface PosTicketCategory {
  id: number;
  max_items: number;
}

export type TicketDiscountType = 'percent' | 'dollar';

export interface Ticket {
  applied_discount_id?: number;
  calculated_total?: number;
  created_at?: string;
  delivery_note?: string;
  delivery_type?: DeliveryType;
  device_id?: number;
  device_ticket_uuid: string;
  diner_id?: number;
  diner_name?: string;
  id?: number;
  original_ticket_id?: number;
  locked?: boolean;
  locked_by_operator_id?: number;
  meal_id?: number;
  meal_plan?: MealPlan;
  mealplan_balance_used?: number;
  mealplan_tender_type?: string;
  number?: number;
  pos_operator_id?: number;
  outstanding_balance?: number;
  pre_tax_subtotal?: number;
  seat_id?: number;
  status?: TicketStatus;
  table_id?: number;
  tax_exempt?: boolean;
  tax_exemption_reason?: string;
  tax_total?: number;
  ticket_items?: TicketItem[];
  total_base_price?: number;
  total_discounts?: number;
  transactions?: TicketTransaction[];
  type?: TicketType;
  ticket_type?: PosTicketItem;
  refund_ticket_number?: string;
  taxes?: Tax[];
  refunds?: TransactionRefund[];
  refund_tickets?: RefundTicket[];
  discounts?: TicketDiscount[];
  diner?: Diner;
  meal_plan_debited?: boolean;
  askedForApplyMealPlan?: boolean;
  local_attributes?: {
    isItemsFiredOrVoided?: boolean;
    ticketItems?: SelfServiceTicketItem[];
    ticketItemFee?: TicketItem;
    subtotal: number;
    discountApplied: number;
  };
  change_due?: number;
  can_be_cancelled?: boolean;
  order_source?: TicketSource;
  meal_delivery_area_timing_id?: number;
  formatted_takeout_date_time?: string;
  takeout_order_payment_status?: TakeoutOrderPaymentStatus;
  takeout_order_status?: TakeoutOrderStatus;
  delivery_room_location?: string;
  ticket_date?: string; // format 'YYYY-MM-DD'
  scheduled_ticket_date?: string;
  scheduled_ticket_time?: string;
  guest_of_diner_id?: number;
  discount_amount?: string;
  discount_type?: TicketDiscountType;
}

export interface PosBaseTicket {
  device_ticket_uuid: string;
  categories?: PosTicketCategory[];
  name: string;
}

export interface PosBaseTicketItem {
  is_fee_item: boolean;
  device_ticket_item_uuid: string;
  name: string;
  outstanding_balance: number;
  tax_amount: string;
  tax: number;
}

export interface TicketItem extends PosBaseTicketItem {
  applied_discount_id?: number;
  base_price: number;
  course: number;
  discount_amount: number;
  food_id?: number;
  notes?: string;
  parent_uuid: string;
  plate_id?: number;
  pos_product_id?: number;
  pre_tax_subtotal: string | number;
  production_notes: string;
  scale_data?: string;
  scale_data_parsed?: Scale;
  status: TicketItemStatus;
  tax: number;
  total: number;
  type: TicketItemType;
  ticket_items: TicketItem[];
  can_be_discounted?: boolean;
  synced?: boolean;
  discounts?: TicketDiscount[];
  gift_card_data?: GiftCardData;
  is_refunded?: boolean;
  source_ticket_id?: number;
  category_id: number;
  is_declining_balance_plan?: boolean;
  is_discounted_by_meal_plan?: boolean;
  price_after_meal_plan_discount?: number;
  meal_plan_discount_amount?: number;
  discount_amount_with_meal_plan?: number;
  created_at?: Date;
  meal_plan_calculation_note?: string;

  service_booking_id?: number | null;

  // meal plan values
  mealplan_valid_item?: true;
  mealplan_discount_applied?: string;

  // KMS quantity
  kitchen_quantity?: string;

  // The extraProp is used to display. The logic is handled in the FE
  extraPropItemHasNotes?: boolean;
  extraPropIsItemModified?: boolean;
  extraPropIsBeingModified?: boolean;
  extraPropIsInFiringMode?: boolean;
  extraPropIsSelectedToFire?: boolean;
  extraPropShowPriceAfterDiscount?: boolean;
  extraPropHasTax?: boolean;
  extraPropHasStrikeText?: boolean;
  extraPropIsCovered?: boolean;

  local_attributes?: {
    // selected?: boolean;
    removed?: boolean;
  };

  child_ticket_items?: TicketItem[];
}

export interface TicketItemRequest {
  device_ticket_item_uuid?: string;
  course?: number;
  scale_data?: string;
  base_price: number;
  category_id?: number;
  name: string;
  quantity?: number;
  pos_product_id?: number;
  food_id?: number;
  plate_id?: number;
  parent_uuid?: string;
  amount?: number;
  gift_card_code?: string;
  type?: TicketItemType;
  kitchen_quantity?: string;
  children_attributes?: TicketItemRequest[];
  service_booking_id?: number;
  is_fee_item?: boolean;
}

export interface Tax {
  name: string;
  amount: number;
  amount_display?: number;
}

export interface TicketTransaction {
  id: number;
  device_transaction_uuid: string;
  type: PaymentRequestType;
  amount: number;
  outstanding_balance: number;
  is_processing: boolean; // False by default. If the credit card gateway transaction has been initialized, it will be true.
  charge_account_id?: number;
  department_id?: number;
  diner_id: number;
  diner_name: string;
  payment_location_id: number;
  pos_charge_account_id: number;
  pos_gift_card_id: number;
  pos_location_id: number;
  pos_meal_plan_id: number;
  pos_ticket_id: number;
  status: string;
  trancloud_transaction_id: string;
}

export interface TicketDiscount extends BaseDiscount {
  amount: number;
}

export interface GiftCardData {
  number: string;
}

export interface RefundTicket {
  original_ticket_id: number;
  device_ticket_uuid: string;
  status: TicketStatus;
}

export const ticketSchema = new schema.Entity(
  'tickets',
  {
    diner: dinerSchema
  },
  { idAttribute: 'device_ticket_uuid' }
);

export interface AddTicketItemResponse {
  ticket_items: TicketItem[];
  ticket: Ticket;
}

export interface AddTicketItemResult {
  ticket_item: TicketItem;
  ticket: Ticket;
  ticket_items?: TicketItem[];
}
