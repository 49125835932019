import { TicketItem, TicketItemType } from 'src/app/models';

export interface SelfServiceTicketItem {
  name: string;
  price: number;
  total: number;
  items: TicketItem[];
  basePrice: number;
  discountAmount: number;
  isCovered?: boolean;
  isCoveredByDeclining?: boolean;
  mealplan_discount_applied?: number;
  meal_plan_calculation_note?: string;
  mealPlanValidItem?: boolean;
  device_ticket_item_uuid?: string;
  childItems?: SelfServiceTicketItem[];
  createdAt?: Date; // Items created on backend should have this value,
  type: TicketItemType;
  food_id?: number;
  pos_product_id?: number;
  plate_id?: number;
}

export enum ErrorCodeKey {
  MultipleDiners = 'MULTIPLE_DINERS',
  NotFoundDiner = 'NOT_FOUND_DINER'
}
