import { createPropertySelectors, createSelector } from '@ngxs/store';
import _ from 'lodash';
//-------------------------------------
import { UtilsSelectors } from 'src/app/store/utils.selectors';
import { SelfServiceState, SelfServiceStateModel } from './self-service.state';
import {
  MealPlanType,
  SelfServiceTicketItem,
  Ticket,
  TicketItem
} from 'src/app/models';
import { LocationSelectors } from 'src/app/store/location/location.selectors';
import { DinersSelectors } from 'src/app/store/diners/diners.selectors';
import { TicketsStateSelectors } from 'src/app/store/tickets/tickets.selectors';
import { SskHelper } from 'src/app/helpers/ssk.helper';

export type SskOrderType = 'dinein' | 'takeout' | 'delivery';
export type SskDinerType = 'guest' | 'staff' | 'resident';
export interface SskCustomOrderType {
  type: SskOrderType;
  requestSpecialInstructions: boolean;
  instructionTitle: string;
}

export class SelfServiceSelectors {
  static slices =
    createPropertySelectors<SelfServiceStateModel>(SelfServiceState);

  static isSskMenuLoaded = createSelector(
    [SelfServiceSelectors.slices.isMenuLoaded],
    (isMenuLoaded) => isMenuLoaded
  );

  static isMacroGridLoaded = createSelector(
    [SelfServiceSelectors.slices.isMacroGridLoaded],
    (isMacroGridLoaded) => isMacroGridLoaded
  );

  static isMenuLoaded = createSelector(
    [
      SelfServiceSelectors.isSskMenuLoaded,
      SelfServiceSelectors.isMacroGridLoaded
    ],
    (isMenuLoaded, isMacroGridLoaded) => isMenuLoaded && isMacroGridLoaded
  );

  static isSetupComplete = createSelector(
    [SelfServiceSelectors.slices.isSetupComplete],
    (isSetupComplete) => isSetupComplete
  );

  static focusedCategory = createSelector(
    [SelfServiceSelectors.slices.focusedCategory],
    (focusedCategory) => focusedCategory
  );

  static focusedMenuItem = createSelector(
    [SelfServiceSelectors.slices.focusedMenuItem],
    (focusedMenuItem) => focusedMenuItem
  );

  static getSelectedTicket = createSelector(
    [UtilsSelectors.selectedTicket],
    (prevTicket) => SelfServiceSelectors.getSskTicket(prevTicket)
  );

  private static convertTicketItemToKioskItem(
    ticket: Ticket,
    ticketItem: TicketItem
  ): SelfServiceTicketItem {
    let childItems = [];

    if (ticketItem.ticket_items?.length) {
      childItems = ticketItem.ticket_items.map((ti) =>
        this.convertTicketItemToKioskItem(ticket, ti)
      );
    }

    return {
      basePrice: +ticketItem.base_price || 0,
      discountAmount: +ticketItem.discount_amount || 0,
      name: ticketItem.name,
      type: ticketItem.type,
      food_id: ticketItem.food_id,
      plate_id: ticketItem.plate_id,
      pos_product_id: ticketItem.pos_product_id,
      price: +ticketItem.pre_tax_subtotal || 0,
      items: [ticketItem],
      childItems,
      total: 1,
      meal_plan_calculation_note: ticketItem.meal_plan_calculation_note,
      mealplan_discount_applied: +ticketItem.mealplan_discount_applied,
      mealPlanValidItem: !!ticketItem.mealplan_valid_item,
      device_ticket_item_uuid: ticketItem.device_ticket_item_uuid,
      isCovered:
        !!ticket.mealplan_tender_type &&
        ticket.mealplan_tender_type !== MealPlanType.DECLINING_BALANCE,
      isCoveredByDeclining:
        (!!ticket.mealplan_tender_type &&
          ticket.mealplan_tender_type === MealPlanType.DECLINING_BALANCE &&
          +ticketItem.pre_tax_subtotal === 0) ||
        ticketItem.local_attributes?.removed
    };
  }

  // move from self-service.selector.ts
  private static getSskTicket(prevTicket: Ticket): Ticket {
    const ticket: Ticket = _.cloneDeep(prevTicket);

    // normal ticket items mean don't have parent uuid and don't have child items.
    const normalTicketItems: TicketItem[] = [];
    const groupedTicketItems: SelfServiceTicketItem[] = [];

    ticket.ticket_items?.forEach((x) => {
      if (x.ticket_items?.length) {
        groupedTicketItems.push(this.convertTicketItemToKioskItem(ticket, x));
      } else {
        normalTicketItems.push(x);
      }
    });

    const kioskChildNormalItem = Object.entries(
      _.groupBy(
        normalTicketItems,
        (item) =>
          item.name + (item.food_id || item.pos_product_id || item.plate_id)
      )
    ).map(([, value]) => {
      const firstItem = _.first(value);
      const item: SelfServiceTicketItem = {
        name: firstItem.name,
        type: firstItem.type,
        pos_product_id: firstItem.pos_product_id,
        plate_id: firstItem.plate_id,
        food_id: firstItem.food_id,
        basePrice: _.sum(value.map((i) => +i.base_price || 0)),
        discountAmount: _.sum(value.map((i) => +i.discount_amount || 0)),
        price: _.sum(value.map((i) => +i.pre_tax_subtotal || 0)),
        items: value,
        total: value.length,
        meal_plan_calculation_note: firstItem.meal_plan_calculation_note,
        mealplan_discount_applied: _.sum(
          value.map((i) => +i.mealplan_discount_applied)
        ),
        mealPlanValidItem: !!firstItem.mealplan_valid_item,
        device_ticket_item_uuid: _.last(value).device_ticket_item_uuid,
        isCovered:
          !!ticket.mealplan_tender_type &&
          ticket.mealplan_tender_type !== MealPlanType.DECLINING_BALANCE,
        isCoveredByDeclining:
          (!!ticket.mealplan_tender_type &&
            ticket.mealplan_tender_type === MealPlanType.DECLINING_BALANCE &&
            +firstItem.pre_tax_subtotal === 0) ||
          firstItem.local_attributes?.removed,
        createdAt: firstItem.created_at
      };

      return item;
    });

    ticket.local_attributes.ticketItems = [
      ...groupedTicketItems,
      ...kioskChildNormalItem
    ];

    if (!ticket.mealplan_balance_used && ticket.meal_plan) {
      ticket.mealplan_balance_used = _.toNumber(ticket.meal_plan.balance_used);
    }

    if (ticket.transactions) {
      ticket.transactions = _.compact(ticket.transactions);
    }

    return ticket;
  }

  static getSskDinerType = createSelector(
    [DinersSelectors.getDiners],
    (diners) => {
      const dinerId = _.first(diners.allIds);
      const selectedDinerType: SskDinerType = dinerId
        ? (diners.byId[dinerId].type as SskDinerType)
        : 'guest';

      return selectedDinerType;
    }
  );

  static getOrderTypesByDinerType = createSelector(
    [LocationSelectors.getSelfServeSettings],
    (settings) => (sskDinerType: SskDinerType) => {
      if (settings.fire_order_on_payment) {
        const OrderTypes: SskCustomOrderType[] = [];

        SskHelper.AllOrderTypes.forEach((orderType) => {
          if (settings[`${sskDinerType}_enable_${orderType}`]) {
            const requestSpecialInstructions =
              settings[
                `${sskDinerType}_${orderType}_request_special_instructions`
              ];
            const instructionTitle =
              settings[
                `${sskDinerType}_${orderType}_special_instruction_prompts`
              ];

            OrderTypes.push({
              type: orderType,
              requestSpecialInstructions,
              instructionTitle
            });
          }
        });

        return OrderTypes;
      }

      return [];
    }
  );

  static selectedOrderType = createSelector(
    [TicketsStateSelectors.normalizedTicket],
    (ticket) => SskHelper.SskDeliveryTypeMapper[ticket.delivery_type].orderType
  );

  static deliveryNote = createSelector(
    [TicketsStateSelectors.normalizedTicket],
    (ticket) => ticket.delivery_note
  );
}
