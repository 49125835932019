import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { map, Observable, pipe } from 'rxjs';
import { DateHelper, HostModeHelper } from 'src/app/helpers';
import { produce } from 'immer';
import _ from 'lodash';
//----------------------------------------------
import {
  BaseDiner,
  MealTime,
  ResponseTable,
  Seat,
  Table,
  TableDetailResponse,
  TableReservation,
  TableReservationDTO
} from 'src/app/models';
import { LOCATION_STATE_TOKEN } from 'src/app/store/location/location.state';
import { BaseService } from '../base.service';
import { MealProvider } from 'src/app/providers/meal.provider';
import { ErrorService } from 'src/app/services/error/error.service';
import { ApiType } from 'src/app/pos.config';

@Injectable({
  providedIn: 'root'
})
export class DiningService extends BaseService {
  constructor(
    store: Store,
    private readonly http: HttpClient,
    private readonly mealProvider: MealProvider,
    private readonly errorService: ErrorService
  ) {
    super(store);
  }

  getTableUrl(tableId?: number, path?: string) {
    let url = this.getLocationUrl('tables');
    if (tableId) {
      url = `${url}/${tableId}`;
    }
    if (path) {
      url = `${url}/${path}`;
    }
    return url;
  }

  getFloorPlan(
    locationId = this.store.selectSnapshot(LOCATION_STATE_TOKEN).location.id
  ): Observable<Table[]> {
    return this.http
      .get<{ tables: ResponseTable[] }>(
        this.getLocationUrl('floor_plan', locationId),
        {
          params: {
            include_reservations: true,
            meal_ids: [
              MealTime.Breakfast,
              MealTime.Lunch,
              MealTime.Dinner
            ].toString(),
            date: DateHelper.format(new Date())
          }
        }
      )
      .pipe(
        this.errorService.retryPipe(ApiType.Query),
        map((response) =>
          response.tables.map((t) => {
            // const table: Table = _.cloneDeep(t) as Table;
            produce(t, (draft: Table) => {
              _.remove(draft.seats, (s) => !s.align || !s.focus);
              draft.pending_seat_assignments =
                HostModeHelper.getPendingAssignmentFromFloorPlan(
                  t.pending_seat_assignments,
                  t.id
                );
            });
            return t as Table;
          })
        )
      );
  }

  getTableDetail(
    table_id: number,
    includeRetryTimeout = true
  ): Observable<TableDetailResponse> {
    const pipeFunc = includeRetryTimeout
      ? pipe(
          this.errorService.retryPipe(ApiType.Query),
          map(({ table }) => table)
        )
      : pipe(map(({ table }) => table));

    return this.http
      .get<{ table: TableDetailResponse }>(this.getTableUrl(table_id))
      .pipe(pipeFunc);
  }

  createTemporarySeat(table_id: number): Observable<Seat> {
    return this.http
      .post<{ data: any }>(this.getTableUrl(table_id, 'temporary_seat'), {
        id: table_id
      })
      .pipe(
        this.errorService.retryPipe(ApiType.Mutate),
        map((response) => response.data)
      );
  }

  fireTable(table_id: number, pos_operator_id, course?: number) {
    const params: any = { pos_operator_id };

    //DEF-4153 if(course) always returns false when course is 0
    if (course != null) {
      params.course = course;
    }
    return this.http
      .post<{ status: string }>(
        this.getTableUrl(table_id, 'fire_table'),
        params
      )
      .pipe(
        this.errorService.retryPipe(ApiType.Mutate),
        map((response) => (response.status ? true : null))
      );
  }

  updateTableAssignment(
    table_ids: number[],
    operator_id?: number
  ): Observable<boolean> {
    const tableIds = table_ids.join(',');
    return this.http
      .put<{ success: boolean }>(this.getLocationUrl('assign_tables'), {
        table_ids: tableIds,
        operator_id
      })
      .pipe(
        this.errorService.retryPipe(ApiType.Mutate),
        map((response) => !!response?.success)
      );
  }

  getAssignedResidents(table_id: number) {
    return this.http
      .get<{ success: boolean; diners: BaseDiner[] }>(
        this.getTableUrl(table_id, 'assigned_residents')
      )
      .pipe(
        this.errorService.retryPipe(ApiType.Query),
        map((response) => response.diners)
      );
  }

  updateFloorPlan(locationId, tables: any[]) {
    return this.http
      .put(this.getLocationUrl('floor_plan', locationId), {
        tables
      })
      .pipe(this.errorService.retryPipe(ApiType.Mutate));
  }

  getTableReservations(date?: string, diner_name?: string) {
    const url = `${this.getLocationUrl()}/reservations`;

    const params: { [k: string]: string } = {};

    if (date) {
      params['date'] = date;
    }

    if (diner_name) {
      params['diner_name'] = diner_name;
    }

    return this.http
      .get<{
        success: boolean;
        reservations: TableReservation[];
      }>(url, { params })
      .pipe(this.errorService.retryPipe(ApiType.Query));
  }

  createTableReservation(dto: TableReservationDTO) {
    const url = `${this.getLocationUrl()}/reservations`;

    return this.http
      .post<{
        reservation: TableReservation;
      }>(url, dto)
      .pipe(
        this.errorService.retryPipe(ApiType.Mutate),
        map(({ reservation }) => reservation)
      );
  }

  editTableReservation(id: number, dto: TableReservationDTO) {
    const url = `${this.getLocationUrl()}/reservations/${id}`;

    return this.http
      .put<{
        reservation: TableReservation;
      }>(url, dto)
      .pipe(
        this.errorService.retryPipe(ApiType.Mutate),
        map(({ reservation }) => reservation)
      );
  }

  deleteTableReservation(id: number) {
    const url = `${this.getLocationUrl()}/reservations/${id}`;

    return this.http
      .delete(url)
      .pipe(this.errorService.retryPipe(ApiType.Mutate));
  }
}
