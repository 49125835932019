import {
  animate,
  AnimationTriggerMetadata,
  keyframes,
  state,
  style,
  transition,
  trigger
} from '@angular/animations';

export enum PosTenantType {
  SYNERGY = 'synergy',
  MEALSUITE = 'mealsuite',
  REVERA = 'revera',
  MYUSUITE = 'myusuite'
}
type PosTenantTypeKey = 'SYNERGY' | 'MEALSUITE' | 'REVERA' | 'MYUSUITE';

export class CommonConstant {
  static readonly DEFAULT_PHOTO = 'assets/image/shared/default-avatar.png';

  /** Key to store uuid under in storage */
  static readonly UUID_KEY = 'ms_device_uuid_key';

  static readonly TENANT_TYPE: Record<PosTenantTypeKey, PosTenantType> = {
    SYNERGY: PosTenantType.SYNERGY,
    MEALSUITE: PosTenantType.MEALSUITE,
    REVERA: PosTenantType.REVERA,
    MYUSUITE: PosTenantType.MYUSUITE
  };

  static readonly ENVIRONMENT = {
    PRODUCTION: 'production',
    DEV: 'dev',
    STAGING: 'staging'
  };

  static readonly REGULAR_DIET = 1;

  static readonly REGULAR_TEXTURE = 6;

  static readonly TENANT_DIET_TEXTURE = {
    synergy: {
      diet_id: 1001,
      texture_id: 1006
    },
    mealsuite: {
      diet_id: 1,
      texture_id: 6
    },
    myusuite: {
      diet_id: 1,
      texture_id: 6
    },
    revera: {
      diet_id: 2001,
      texture_id: 206
    }
  };

  static readonly MAX_VALUE = 1e6;

  static readonly NO_COURSE = 0;
  static readonly ALL_COURSES = [1, 2, 3, CommonConstant.NO_COURSE];

  /** Date and time related functions and constants */
  static readonly MS_DATE_TIME = {
    DATE_FORMAT: 'yyyy-MM-dd',
    TIME_FORMAT: 'HHmm',
    TIME_FORMAT_A: 'hh:mm A',
    TIME_FORMAT_24h: 'HH:mm',
    DATE_TIME_FORMAT: 'YYYY-MM-DD HH:mm:ss',
    DATE_TIME_ISO_FORMAT: 'YYYY-MM-DDTHH:mmZ',
    FULL_DATE: 'MMMM DD, YYYY',
    SHORT_DATE: 'MMM dd yyyy'
  };

  static readonly MOMENT_DATE_TIME = {
    // moment format
    DATE: 'YYYY-MM-DD',
    DATE_TIME: 'YYYY-MM-DD HH:mm a',
    SORT_DATE: 'MMMM DD',
    SORT_DATE_TIME: 'MMMM DD hh:mm a',
    TAKEOUT_DISPLAY_DATE: 'ddd, MMM DD, YYYY',
    TAKEOUT_DISPLAY_DATE_TIME: 'ddd, MMM DD, YYYY | hh:mm A'
  };

  static readonly WEBSOCKET_CHANNELS = {
    DEVICE_CHANNEL: 'DeviceActivityChannel',
    POS_CHANNEL: 'PosChannel',
    MENU_CHANNEL: 'MenuboardChannel',
    POS_OPERATOR_CHANNEL: 'OperatorChannel'
  };

  static readonly TIMEOUT_SETUP = {
    POS_TIMEOUT: 60, // Seconds before timeout complete
    POS_IDLE: 9 * 60 // Idle time before timeout warning
  };

  static readonly MAX_LOADING_TIME = 3000;
  static readonly MIN_LOADING_TIME = 1000;

  static readonly CLIENT_SELECT_MODAL_ID = 'client-select-modal-id';

  static readonly POS_DINING_IDLE_TIME = 50; // 50 seconds

  static readonly MIN_WEBVIEW_VERSION = 100;

  static readonly MAX_VARIABLE_PRICE_INPUT_VALUE = 30000;

  static readonly ANIMATION_TIMING = 700; // ms;
  static readonly FLOOR_PLAN_ANIMATIONS: AnimationTriggerMetadata[] = [
    trigger('a1', [
      state('1', style({ minWidth: '*' })),
      transition(
        '1 => void', // Deselecting a seat
        animate(
          `${CommonConstant.ANIMATION_TIMING}ms ease`,
          keyframes([
            style({ minWidth: '*', width: '*', offset: 0 }),
            style({ minWidth: '0', width: '0', offset: 1 })
          ])
        )
      ),
      transition(
        'void => 1', // Selecting a seat
        animate(
          `${CommonConstant.ANIMATION_TIMING}ms ease`,
          keyframes([
            style({ minWidth: '0', width: '0', offset: 0 }),
            style({ minWidth: '*', width: '*', offset: 1 })
          ])
        )
      )
    ])
  ];

  static readonly LG_SCREEN_BREAKPOINT = 992;
  static readonly MOBILE_BREAKPOINT = 520;
}
