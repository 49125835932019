import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';

import { environment } from 'src/environments/environment';
import { CommonConstant, PosTenantType } from '../constants';
import { SetApiUrl } from '../store/app/app.action';

/** API URL Generator */
export const MS_API_DOMAIN = (subdomain: string) => {
  let env = '';
  if (environment.MSPOS_ENVIRONMENT !== CommonConstant.ENVIRONMENT.PRODUCTION) {
    if (environment.MSPOS_ENVIRONMENT === CommonConstant.ENVIRONMENT.STAGING) {
      env = CommonConstant.ENVIRONMENT.STAGING;
    } else if (
      environment.MSPOS_ENVIRONMENT === CommonConstant.ENVIRONMENT.DEV
    ) {
      env = CommonConstant.ENVIRONMENT.DEV;
    }
  }
  //Use when deploying
  return (
    environment.API_URL ||
    `https://${subdomain}${!!env ? `.${env}` : ''}.touch.mealsuite.com`
  );
};

@Injectable({
  providedIn: 'root'
})
export class ConfigProvider {
  constructor(private readonly store: Store) {}

  configHost(subdomain = CommonConstant.TENANT_TYPE.SYNERGY) {
    subdomain = subdomain.replace('.live', '') as PosTenantType;
    const apiUrl = this.apiURLFor(subdomain);
    this.store.dispatch(new SetApiUrl(apiUrl));
  }

  /** Get the api urls for the given provider */
  private apiURLFor(subdomain: string) {
    return MS_API_DOMAIN(subdomain);
  }
}
