import { createPropertySelectors, createSelector } from '@ngxs/store';
import { denormalize } from 'normalizr';
import _ from 'lodash';
//--------------------------------------------
import { TicketItemsState, TicketItemsStateModel } from './ticket-items.state';
import { TicketItem, ticketItemsSchema } from 'src/app/models';
import { TicketsStateSelectors } from 'src/app/store/tickets/tickets.selectors';

export class TicketItemsStateSelectors {
  static slices =
    createPropertySelectors<TicketItemsStateModel>(TicketItemsState);

  private static selectedTicketItem = createSelector(
    [TicketItemsStateSelectors.slices.selectedTicketItem],
    (selectedTicketItem) => selectedTicketItem
  );

  private static ticketItems = createSelector(
    [TicketItemsStateSelectors.slices.ticketItems],
    (ticketItems) => ticketItems
  );

  static ticketItemsById = createSelector(
    [TicketItemsStateSelectors.ticketItems],
    (ticketItems) => ticketItems.byId
  );

  static getSelectedTicketItemId = createSelector(
    [TicketItemsStateSelectors.selectedTicketItem],
    (selectedTicketItemId) => selectedTicketItemId
  );

  static getTicketItems = createSelector(
    [TicketItemsStateSelectors.ticketItems],
    (ticketItems) => ticketItems
  );

  static getWaitingTicketItem = createSelector(
    [TicketItemsStateSelectors.slices.waitingTicketItem],
    (waitingTicketItem) => waitingTicketItem
  );

  static isSelectedTicketItem = createSelector(
    [TicketItemsStateSelectors.getSelectedTicketItemId],
    (selectedTiUuid) => (id: string) => id === selectedTiUuid
  );

  static getSelectedTicketItem = createSelector(
    [
      TicketItemsStateSelectors.getSelectedTicketItemId,
      TicketItemsStateSelectors.ticketItemsById
    ],
    (selectedTicketItem, ticket_items) => {
      if (selectedTicketItem == null) {
        return null;
      }

      const denormalizedTicketItem: TicketItem = denormalize(
        selectedTicketItem,
        ticketItemsSchema,
        {
          ticket_items
        }
      );
      return denormalizedTicketItem;
    }
  );

  static isLoadingPrice = createSelector(
    [
      TicketItemsStateSelectors.getWaitingTicketItem,
      TicketsStateSelectors.selectedTicketId
    ],
    (waitingTicketItems, deviceTicketUuid) =>
      waitingTicketItems?.length &&
      _.first(waitingTicketItems)?.device_ticket_uuid === deviceTicketUuid
  );

  static getTicketItemByUuid = createSelector(
    [TicketItemsStateSelectors.ticketItemsById],
    (ticketItemsById) => (ticketItemUuid: string) =>
      ticketItemsById && ticketItemsById[ticketItemUuid]
  );

  // move from self-service.selector.ts
  static getIsEmptyTicket = createSelector(
    [TicketItemsStateSelectors.ticketItemsById],
    (ticketItems) => _.every(ticketItems, (ti) => !ti.created_at)
  );
}
