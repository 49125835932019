import { PosTenantType } from 'src/app/constants';
import { environment } from 'src/environments/environment';
import { PosEnv } from 'src/environments/environment.model';

export class AppUtil {
  /** API URL Generator */
  static configHost(tenant: PosTenantType = PosTenantType.SYNERGY): string {
    const env = AppUtil.getEnv();
    const subdomain = AppUtil.getTenantSubdomain(tenant);

    //Use when deploying
    return (
      environment['API_URL'] || `https://${subdomain}${env}.touch.mealsuite.com`
    );
  }

  private static getTenantSubdomain(
    tenant: PosTenantType = PosTenantType.SYNERGY
  ): string {
    return (tenant || 'synergy').replace('.live', '');
  }

  /** Get the environment */
  private static getEnv(
    env: PosEnv = environment.MSPOS_ENVIRONMENT as PosEnv
  ): string {
    if (env === 'production') {
      return '';
    }
    return '.' + env;
  }

  static getTenantAndFacilityFromAccountId(
    facilityCode: string
  ): [number, PosTenantType] {
    let facilityId: number, tenant: PosTenantType;
    if (isNaN(+facilityCode)) {
      const [type, id] = facilityCode.split(/(\d+)/, 2);
      facilityId = Number(id);
      switch (type.toLowerCase()) {
        case 'c':
          tenant = PosTenantType.SYNERGY;
          break;
        case 'r':
          tenant = PosTenantType.REVERA;
          break;
        case 'us':
          tenant = PosTenantType.MYUSUITE;
          break;
        default:
          tenant = PosTenantType.MEALSUITE;
          break;
      }
    } else {
      facilityId = +facilityCode;
      tenant = PosTenantType.MEALSUITE;
    }

    return [facilityId, tenant];
  }
}
